import { Project, UserProject } from '@clef/shared/types';
import { isProjectOwner } from '@clef/shared/utils';
import { ProjectLabelTypeDisplayProps } from './project';

export enum SortOption {
  LastOpened = 'Last Opened',
  DateCreatedNewest = 'Date Created: Newest',
  DateCreatedOldest = 'Date Created: Oldest',
}

export interface QueryTag {
  label: string;
  group: string;
  id: string;
}

export const PROJECT_TYPE = t('Project Type');

export const sortProjects = (
  projects: Project[] | null,
  option: SortOption,
  currentUserId?: string,
) => {
  if (projects) {
    if (option === SortOption.LastOpened && currentUserId) {
      // Precalculate the compare key to avoid redundant computation during sorting
      const newProjects = projects.map(project => {
        return {
          ...project,
          temporaryCompareKey: project.usersRoles?.find(user => user?.userId === currentUserId),
        };
      }) as (Project & { temporaryCompareKey: UserProject })[];

      return (
        newProjects
          .sort((a, b) => {
            const userProjectA = a.temporaryCompareKey;
            const userProjectB = b.temporaryCompareKey;

            if (userProjectA?.lastOpenedTime && userProjectB?.lastOpenedTime) {
              return (
                Date.parse(userProjectB.lastOpenedTime) - Date.parse(userProjectA.lastOpenedTime)
              );
            } else if (userProjectA?.lastOpenedTime) {
              return -1;
            } else if (userProjectB?.lastOpenedTime) {
              return 1;
            } else {
              return 0;
            }
          })
          // Remove the redundant compare key
          .map(newProjects => {
            const { temporaryCompareKey, ...rest } = newProjects;
            return rest as Project;
          })
      );
    } else if (option === SortOption.DateCreatedNewest) {
      return projects.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
    } else if (option === SortOption.DateCreatedOldest) {
      return projects.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
    }

    // If no matching conditions, return the original projects list
    return projects;
  }

  return [];
};

export const filterProjects = (
  projects: Project[] | null,
  searchText: string,
  queryTags?: QueryTag[],
) => {
  if (projects) {
    searchText = searchText.toLowerCase();
    let projectTypeTag: string = '';
    if (queryTags) {
      const projectTypeTagArr = queryTags?.filter((tag: QueryTag) => {
        return tag.group === PROJECT_TYPE;
      });
      if (projectTypeTagArr.length > 1) {
        throw Error(
          'Function filterProject should only have one projectTypeTag in Parameter queryTags',
        );
      }
      if (projectTypeTagArr.length === 1) {
        projectTypeTag = projectTypeTagArr[0].label;
      }
    }
    const projectsFilteredByQueryTags = projectTypeTag
      ? projects.filter(project => {
          const projectType = project.labelType
            ? ProjectLabelTypeDisplayProps[project.labelType].title
            : '';
          return projectType === projectTypeTag;
        })
      : [...projects];

    const newProjects = searchText
      ? projectsFilteredByQueryTags.filter(project => {
          const projectName = project.name;
          const ownerName =
            project.usersRoles?.find(user => isProjectOwner(user.role))?.userName || '';
          const projectType = project.labelType
            ? ProjectLabelTypeDisplayProps[project.labelType].title
            : '';

          return `${projectName} ${ownerName} ${projectType}`.toLowerCase().includes(searchText);
        })
      : [...projectsFilteredByQueryTags];

    return newProjects;
  }

  return [];
};

export const getRecentProjects = (
  projects: Project[] | null,
  currentUserId: string,
  projectsNum: number = 4,
) => {
  if (projects) {
    const newProjects = projects.filter(project => {
      return project.usersRoles?.find(
        user => user?.userId === currentUserId && user.lastOpenedTime,
      );
    });

    return sortProjects(newProjects, SortOption.LastOpened, currentUserId).slice(0, projectsNum);
  }

  return [];
};
