import React from 'react';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { Typography, Grid } from '@material-ui/core';
import DropFileZone from '../components/DropFileZone';
import { useAppDispatch } from '../../../../store';
import { addClassifiedFile } from '../../../../store/uploadState';
import ClassifiedInstruction1 from '../../../../images/classification_media_upload/classified_instruction_1.png';
import ClassifiedInstruction2 from '../../../../images/classification_media_upload/classified_instruction_2.png';
import { useUploadStyles } from '../styles';
import { useDefectSelector } from '../../../../store/defectState/actions';
import { useDialog } from '../../../Layout/components/useDialog';
import { ReachLimitException } from '@/store/uploadState/utils';
import { ExceedType } from '@/components/Layout/components/UsageExceedDialog';

export interface ClassifiedUploadDropFileZoneProps {
  fileCapacity?: number | null;
  fileLimit?: number | null;
  isNewCreditSystem?: boolean;
  disabled?: boolean;
}

const ClassifiedUploadDropFileZone: React.FC<ClassifiedUploadDropFileZoneProps> = ({
  fileCapacity,
  fileLimit,
  isNewCreditSystem,
  disabled,
}) => {
  const styles = useUploadStyles();
  const uploadData = useTypedSelector(state => state.uploadState.uploadData);

  const allDefects = useDefectSelector();
  const dispatch = useAppDispatch();

  const { showUsageExceedDialog } = useDialog();
  return (
    <DropFileZone
      onDrop={async files => {
        try {
          await dispatch(
            addClassifiedFile({
              files: files,
              existingDefects: allDefects,
              capacity: fileCapacity,
              limit: fileLimit,
              throwOnReachLimit: isNewCreditSystem,
            }),
          ).unwrap();
        } catch (e) {
          if (e instanceof ReachLimitException) {
            showUsageExceedDialog(ExceedType.Upload, e.fileCount, fileCapacity!);
          }
        }
      }}
      directory
      disabled={disabled}
      dataTestId="classified-image-upload-input"
    >
      <Typography variant="body1" component="div" className={styles.dropzoneText}>
        {t('Drag and drop ')}
        <strong>{t('folders/images ')}</strong>
        {t('here, or click to select files')}
      </Typography>
      {!uploadData.length && (
        <Grid container direction="row">
          <Grid container item xs={6} direction="column" alignItems="center">
            <img src={ClassifiedInstruction1} className={styles.instructionImg} />
            <Typography className={styles.dropzoneText}>
              {t('We accept JPEG, PNG, BMP, MPO and TIFF image format')}
            </Typography>
          </Grid>
          <Grid container item xs={6} direction="column" alignItems="center">
            <img src={ClassifiedInstruction2} className={styles.instructionImg} />
            <Typography className={styles.dropzoneText}>
              {t(
                'Drag & drop each class folder(s) here to upload and classify the media as part of that class.',
              )}
            </Typography>
          </Grid>
        </Grid>
      )}
    </DropFileZone>
  );
};

export default ClassifiedUploadDropFileZone;
