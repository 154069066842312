import React, { useCallback } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Button } from '@clef/client-library';

import { useAppDispatch } from '../../../../../store';
import {
  addSegmentationImageFile,
  deleteSegmentationImageFile,
} from '../../../../../store/uploadState';
import ImageUploadIcon from '../../../../../images/segmentation_media_upload/image_upload.png';
import ImageIcon from '../../../../../images/segmentation_media_upload/image.png';
import FolderIcon from '../../../../../images/segmentation_media_upload/folder.png';
import useUploadFileBlockQueue, {
  UploadFileBlock,
} from '../../../../../hooks/useUploadFileBlockQueue';
import { useNewUploadStyles } from '../../styles';
import { BaseDropFileZone } from './BaseDropFileZone';
import { ReachLimitException } from '@/store/uploadState/utils';
import { useDialog } from '@/components/Layout/components/useDialog';
import { ExceedType } from '@/components/Layout/components/UsageExceedDialog';
import { OverSizeLimitException } from '@/store/uploadState/segmentationActions';
import { useImageSizeLimit, useShowOverSizedImagesSnackbar } from '../../imageSizeUtils';
import { acceptedImageFiles } from '@/utils';

export interface SegmentationUploadImageDropFileZoneProps {
  fileCapacity?: number | null;
  fileLimit?: number | null;
  isNewCreditSystem?: boolean;
  disabled?: boolean;
}

export const SegmentationUploadImageDropFileZone: React.FC<
  SegmentationUploadImageDropFileZoneProps
> = ({ fileCapacity, disabled, fileLimit, isNewCreditSystem }) => {
  const styles = useNewUploadStyles();
  const dispatch = useAppDispatch();
  const { imageBlocks } = useUploadFileBlockQueue();

  const getChipLabel = useCallback(
    (block: UploadFileBlock) => {
      return block.folderName ? (
        <Grid container alignItems="center" justifyContent="center">
          <img src={FolderIcon} className={styles.segmentationDropZoneChipIcon} />
          <span>{block.folderName}</span>
        </Grid>
      ) : (
        <Grid container alignItems="center" justifyContent="center">
          <img src={ImageIcon} className={styles.segmentationDropZoneChipIcon} />
          <span>
            {block.files.length === 1
              ? t('1 image')
              : t('{{count}} images', { count: block.files.length })}
          </span>
        </Grid>
      );
    },
    [styles.segmentationDropZoneChipIcon],
  );

  const { showUsageExceedDialog } = useDialog();

  const sizeLimit = useImageSizeLimit();
  const showOverSizedImagesSnackbar = useShowOverSizedImagesSnackbar();

  return (
    <BaseDropFileZone
      blocks={imageBlocks}
      title={t('Image')}
      acceptFileTypesText={t('PNG, JPEG, BMP, MPO, TIFF')}
      imageSrc={ImageUploadIcon}
      guideMessage={{
        mini: (
          <Typography className={styles.dropzoneText}>
            {t('Drag and drop folders/images, or ')}
            <strong className={styles.chooseFileText}>{t('Choose File')}</strong>
          </Typography>
        ),
        full: (
          <>
            <Typography className={styles.dropzoneText}>
              {t('Drag and drop folders/images, or')}
            </Typography>
            <Typography>
              <Button
                id="choose-image-file"
                variant="contained"
                color="primary"
                className={styles.chooseFileButton}
                disabled={disabled}
              >
                {t('Choose File')}
              </Button>
            </Typography>
          </>
        ),
      }}
      onDrop={async files => {
        try {
          await dispatch(
            addSegmentationImageFile({
              files,
              capacity: fileCapacity,
              limit: fileLimit,
              throwOnReachLimit: isNewCreditSystem,
              sizeLimit,
            }),
          ).unwrap();
        } catch (e) {
          if (e instanceof ReachLimitException) {
            showUsageExceedDialog(ExceedType.Upload, e.fileCount, fileCapacity!);
          } else if (e instanceof OverSizeLimitException) {
            showOverSizedImagesSnackbar(e);
          }
        }
      }}
      onDelete={files => {
        dispatch(deleteSegmentationImageFile(files));
      }}
      getChipLabel={getChipLabel}
      acceptedFiles={acceptedImageFiles}
      acceptMultipleDrops={true}
      dropZoneDataTestId={'seg-image-upload-input'}
      hasAcceptedFiles={!!imageBlocks.length}
      disabled={disabled}
    />
  );
};
