import { convertLabelTypeToExperimentType } from '@clef/shared/utils';
import * as t from '@clef/shared/types';
import {
  ActionItemsResponse,
  ApiResponse,
  BoxSuggestion,
  ConfusionMatrixKey,
  CorrelatedTrainingImagesResponse,
  LabelType,
  MediaId,
  ProjectId,
  TrainHealthData,
  ModelArchSchemaItem,
  GetTrainingCreditCostRequest,
} from '@clef/shared/types';

import { API_GATEWAY_URL } from '../constants';
import { BaseAPI } from './base_api';

export const prefix = 'experiment_report';

type JobId = t.JobInfo['jobId'];

class ExperimentReportApi extends BaseAPI {
  async getSingleModelReport(params: {
    projectId: t.ProjectId;
    jobId: t.JobInfo['id'];
  }): Promise<t.SingleModelReport> {
    const { jobId, ...queryParams } = params;
    return this.get(`v1/reports/${jobId}`, queryParams, true);
  }

  async getSingleModelReportDetails(params: {
    projectId: t.ProjectId;
    jobId: t.JobInfo['id'];
    limit: number;
    offset: number;
    groundTruth?: string;
    prediction?: string;
    confusionType?: t.ConfusionMatrixKey;
  }): Promise<t.SingleModelReportDetails> {
    const { jobId, ...queryParams } = params;
    return this.get(`v1/reports/${jobId}/details`, queryParams, true);
  }

  async getComparisonReport(params: {
    candidateJobId: t.JobInfo['id'];
    baselineJobId: t.JobInfo['id'];
    projectId: t.ProjectId;
  }): Promise<t.ComparisonReport> {
    const { baselineJobId, candidateJobId, ...queryParams } = params;
    return this.get(
      `v1/reports/${baselineJobId}`,
      { compareWith: candidateJobId, ...queryParams },
      true,
    );
  }

  async getComparisonReportDetails(params: {
    candidateJobId: t.JobInfo['id'];
    baselineJobId: t.JobInfo['id'];
    projectId: t.ProjectId;
    limit: number;
    offset: number;
    groundTruth?: string;
    prediction?: string;
    candidatePrediction?: string;
    confusionType?: t.ConfusionMatrixKey;
  }): Promise<t.ComparisonReportDetails> {
    const { baselineJobId, candidateJobId, ...queryParams } = params;
    return this.get(
      `v1/reports/${baselineJobId}/details`,
      { compareWith: candidateJobId, ...queryParams },
      true,
    );
  }

  async getAEAStatusByConfusionkey(params: {
    jobId: t.JobInfo['id'];
    confusionType: ConfusionMatrixKey;
  }): Promise<{ [key: string]: string[] }> {
    const { jobId, confusionType } = params;

    return this.get(`v1/reports/${jobId}/aea/status?errorType=${confusionType}`, undefined, true);
  }

  async getAllActionItems(params: { jobId: JobId }): Promise<ActionItemsResponse[]> {
    const { jobId } = params;

    return this.get(`v1/reports/${jobId}/aea/actions_detail`, undefined, true);
  }

  async addActionableErrorAnalysisActionItems(params: {
    jobId: JobId;
    mediaId: string;
    errorId: string;
    errorType: ConfusionMatrixKey;
    defectId: number;
    defectName: string;
    actionItems: string[];
  }) {
    const { jobId, mediaId, errorId, errorType, defectId, defectName, actionItems } = params;
    const body = {
      mediaId,
      actionItems,
      errorId,
      errorType,
      defectId,
      defectName,
    };

    return this.post(`v1/reports/${jobId}/aea/actions_detail`, JSON.stringify(body));
  }

  async getAEALatestActionItemsByErrorId(params: {
    jobId: string;
    mediaId: number;
    errorId: string;
  }) {
    const { jobId, mediaId, errorId } = params;

    return this.get(
      `v1/reports/${jobId}/aea/actions_detail?mediaId=${mediaId}&errorId=${errorId}`,
      undefined,
      true,
    );
  }

  async getCorrelatedTrainingImages(params: {
    jobId: JobId;
    mediaId: MediaId;
    errorId: string;
    projectId: ProjectId;
  }): Promise<CorrelatedTrainingImagesResponse[]> {
    const { jobId, mediaId, errorId, projectId } = params;

    return this.get(
      `v1/reports/${jobId}/aea/similar_predictions?mediaId=${mediaId}&errorId=${errorId}&projectId=${projectId}`,
      undefined,
      true,
    );
  }

  async getSuggestions(projectId: number): Promise<{
    suggestions: {
      media_id: number;
      suggestions: BoxSuggestion[];
    }[];
    total: number;
  }> {
    // TODO: pagination
    return this.get(`v1/labels/suggestions/latest`, { projectId, limit: 100 }, true);
  }

  async updateSuggestionStatus(
    projectId: number,
    suggestionId: number,
    status: 'accepted' | 'rejected',
  ) {
    return this.patch(`v1/labels/suggestions/${suggestionId}?projectId=${projectId}`, { status });
  }

  async createSuggestionTask(projectId: number) {
    return this.post(`v1/labels/suggestion-tasks?projectId=${projectId}`);
  }

  async getSuggestionTaskStatus(projectId: number): Promise<{
    id: string;
    source: 'user-request' | 'train-job' | 'label-auto-trigger';
    status: 'starting' | 'running' | 'succeeded' | 'failed';
  }> {
    return this.get('v1/labels/suggestion-tasks/latest', { projectId }, true);
  }

  async savePostProcessing(projectId: ProjectId, postProcessing: Object) {
    return this.putJSON(
      `v1/postprocessing/segmentation-config?projectId=${projectId}`,
      postProcessing,
    );
  }

  async loadPostProcessing(projectId: ProjectId): Promise<
    ApiResponse<{
      classificationRules: {
        defaultClassification: string;
        rules: any[];
      };
      defectMap: Record<
        number,
        {
          defectId?: number;
          name: string;
        }
      >;
      noiseFilter: Record<number, number>;
    }>
  > {
    return this.get(`v1/postprocessing/segmentation-config?projectId=${projectId}`);
  }

  async startInstantLearningTrain(
    projectId: ProjectId,
    mediaId: number,
  ): Promise<
    ApiResponse<{
      jobId: string;
    }>
  > {
    return this.post(
      `v1/train/instant-learning-jobs?projectId=${projectId}&currentViewMediaId=${mediaId}`,
      null,
    );
  }

  async getTrainHealth(): Promise<TrainHealthData> {
    return this.get('v1/train/health', undefined, true);
  }

  async getSAMOnnxModel(): Promise<ArrayBuffer> {
    const controller = new AbortController();
    const { signal } = controller;

    // set timer
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => {
        controller.abort();
        reject(
          new Error(
            'Smart Labeling is currently unavailable due to high demand. Please try again later.',
          ),
        );
      }, 30000);
    });

    const requestPromise = this.get(
      'v1/embeddings/assist-label-onnx-model',
      {},
      false,
      { signal },
      true,
    );

    return Promise.race([timeoutPromise, requestPromise]);
  }

  async getImageEmbedding(projectId: ProjectId, mediaPath: string): Promise<ArrayBuffer> {
    const controller = new AbortController();
    const { signal } = controller;

    // set timer
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => {
        controller.abort();
        reject(
          new Error(
            'Smart Labeling is currently unavailable due to high demand. Please try again later.',
          ),
        );
      }, 30000);
    });

    const requestPromise = this.get(
      `v1/embeddings/sam-embeddings`,
      {
        projectId,
        mediaPath,
      },
      false,
      { signal },
      true,
    );

    return Promise.race([timeoutPromise, requestPromise]);
  }

  async getCustomTrainingCreditCost(
    params: Omit<GetTrainingCreditCostRequest, 'experimentType'> & { labelType: LabelType },
  ): Promise<ApiResponse<{ trainCredits: number }>> {
    const { labelType, ...otherParams } = params;
    return this.get('v1/credits/train', {
      ...otherParams,
      experimentType: convertLabelTypeToExperimentType(params.labelType),
    });
  }

  async getCustomTrainingInferenceCost(params: {
    modelSize: string;
    height: number;
    width: number;
    labelType: LabelType;
  }): Promise<ApiResponse<{ inferenceCredits: number }>> {
    const { labelType, ...otherParams } = params;
    return this.get('v1/credits/inference', {
      ...otherParams,
      experimentType: convertLabelTypeToExperimentType(params.labelType),
    });
  }

  async getModelInferenceCost(params: {
    jobId: string;
    projectId: ProjectId;
  }): Promise<ApiResponse<{ inferenceCredits: number }>> {
    return this.get(`v1/credits/inference/${params.jobId}`, {
      projectId: params.projectId,
    });
  }

  async getModelArchSchemas(
    projectId: number,
    labelType: LabelType,
  ): Promise<ModelArchSchemaItem[]> {
    return this.get(
      `train/model-schemas`,
      { projectId, experimentType: convertLabelTypeToExperimentType(labelType) },
      true,
    );
  }
}

export default new ExperimentReportApi(prefix, API_GATEWAY_URL);
