import React from 'react';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { Typography, Grid, Box } from '@material-ui/core';
import DropFileZone from '../components/DropFileZone';
import { useAppDispatch } from '../../../../store';
import { addObjectDetectionFile } from '../../../../store/uploadState';
import { useUploadStyles } from '../styles';
import { ReachLimitException } from '@/store/uploadState/utils';
import { useDialog } from '@/components/Layout/components/useDialog';
import { ExceedType } from '@/components/Layout/components/UsageExceedDialog';
import { acceptedImageFiles } from '@/utils';

export interface InstantLearningUploadDropFileZoneProps {
  fileCapacity?: number | null;
  fileLimit?: number | null;
  isNewCreditSystem?: boolean;
  disabled?: boolean;
}

const PngSvg = () => (
  <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.5"
      y="0.889648"
      width="39"
      height="28.2308"
      rx="1.5"
      fill="#F4F8FE"
      stroke="#69717A"
    />
    <path
      d="M17.0001 9.90873C17.0001 12.3409 15.0284 14.3126 12.5962 14.3126C10.1641 14.3126 8.19238 12.3409 8.19238 9.90873C8.19238 7.47655 10.1641 5.50488 12.5962 5.50488C15.0284 5.50488 17.0001 7.47655 17.0001 9.90873Z"
      fill="#F3F3F4"
      stroke="#69717A"
    />
    <path
      d="M9.7343 21.8273L2.45215 29.3081H37.5964L25.337 16.5265C24.9435 16.1163 24.2876 16.1161 23.8939 16.5262L15.3848 25.3898L11.0968 21.7615C10.6958 21.4222 10.1007 21.4509 9.7343 21.8273Z"
      fill="#F3F3F4"
      stroke="#69717A"
    />
  </svg>
);

const InstantLearningUploadDropFile: React.FC<InstantLearningUploadDropFileZoneProps> = ({
  fileCapacity,
  fileLimit,
  isNewCreditSystem,
  disabled,
}) => {
  const styles = useUploadStyles();
  const uploadData = useTypedSelector(state => state.uploadState.uploadData);
  const dispatch = useAppDispatch();
  const { showUsageExceedDialog } = useDialog();

  const acceptStrs = ['JPG', 'PNG', 'BMP', 'MPO', 'TIFF'];

  return (
    <DropFileZone
      onDrop={async files => {
        try {
          await dispatch(
            addObjectDetectionFile({
              files,
              capacity: fileCapacity,
              limit: fileLimit,
              throwOnReachLimit: isNewCreditSystem,
            }),
          ).unwrap();
        } catch (e) {
          if (e instanceof ReachLimitException) {
            showUsageExceedDialog(ExceedType.Upload, e.fileCount, fileCapacity!);
          }
        }
      }}
      accept={acceptedImageFiles}
      disabled={disabled}
      className={styles.instantLearningZone}
    >
      <Typography variant="body1" component="div" className={styles.dropzoneText}>
        {t('Drag and drop images or folders here')}
      </Typography>
      {!uploadData.length && (
        <Grid container direction="row">
          <Grid container item xs={12} direction="column" alignItems="center">
            <Box display="flex" marginTop={8} style={{ gap: 24 }}>
              {acceptStrs.map(type => (
                <Box
                  key={type}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box marginBottom={2}>
                    <PngSvg />
                  </Box>
                  <Typography component="div" className={styles.acceptLabel}>
                    {type}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      )}
    </DropFileZone>
  );
};

export default InstantLearningUploadDropFile;
