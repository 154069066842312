import { createAsyncThunk } from '@reduxjs/toolkit';
import { FileWithPath } from 'react-dropzone';
import { ReduxState, AppDispatch } from '..';
import {
  addFileConcurrency,
  blobToBase64,
  getJsonContent,
  isTiffFile,
  ParallelTaskQueue,
} from '../../utils';
import { fileListUniqueByKey, getFileKey, truncateList } from './utils';
import { UploadStatus, UploadFile, SegmentationMask, DefectMap } from './types';
import { isSegDefectMapValid } from '@clef/shared/utils';
import { getImageSizeAsync } from '@/components/Dialogs/UploadFullscreen/imageSizeUtils';
import { tiffToPngListAsync } from '@clef/client-library/src/utils/tiffToPngList';

export class OverSizeLimitException extends Error {
  overSizedImages: UploadFile[];
  sizeLimit: number;
  constructor(message: string, overSizedImages: UploadFile[], sizeLimit: number) {
    super(message);
    this.overSizedImages = overSizedImages;
    this.sizeLimit = sizeLimit;
  }
}

export const duplicateFileWarningMsg = t(
  'Duplicate file names detected. Replaced old files with new files',
);

export const filesTruncatedWarningMsg = (limit: number | null | undefined) =>
  typeof limit === 'number'
    ? t('The image upload limit has been reached ({{limit}}).', { limit })
    : t('The image upload limit has been reached limit.');

export const addSegmentationImageFile = createAsyncThunk<
  UploadFile[],
  {
    files: FileWithPath[];
    capacity?: number | null;
    limit?: number | null;
    throwOnReachLimit?: boolean;
    sizeLimit?: number | null;
  },
  { state: ReduxState; dispatch: AppDispatch; rejectValue: void }
>(
  'uploadState/addSegmentationImageFile',
  async ({ files, capacity, limit, throwOnReachLimit, sizeLimit }, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { uploadData, segmentationMasks, defectMap } = getState().uploadState;

    const maskLookup = (segmentationMasks ?? []).reduce((lookup, mask) => {
      lookup[mask.key] = mask;
      return lookup;
    }, {} as Record<string, SegmentationMask>);

    const newAddedFileList: UploadFile[] = [];
    const addFileQueue = new ParallelTaskQueue(addFileConcurrency);
    files
      .filter(f => !f.name.startsWith('.'))
      .forEach(f => {
        addFileQueue.add(async () => {
          const lookupKey = getFileKey(f);
          const mask = maskLookup[lookupKey];

          const makeUploadFile = (
            f: FileWithPath | File,
            key: string,
            mask: SegmentationMask | undefined,
          ): UploadFile => {
            const uploadFile: UploadFile = {
              key: key,
              file: f,
              status: UploadStatus.NotStarted,
              progress: 0,
            };

            if (!uploadFile.initialLabel) {
              uploadFile.initialLabel = {};
            }
            if (mask) {
              uploadFile.initialLabel.segMask = mask.base64String;
              uploadFile.initialLabel.unlabeledAsNothingToLabel = false;
            }
            if (defectMap) {
              uploadFile.initialLabel.segDefectMap = JSON.stringify(defectMap.map);
            }
            return uploadFile;
          };

          if (isTiffFile(f.name)) {
            const pngList = await tiffToPngListAsync(f);
            // Only apply mask to single-image TIFF
            if (pngList.length === 1) {
              const uploadFile = makeUploadFile(pngList[0], lookupKey, mask);
              newAddedFileList.push(uploadFile);
            } else {
              pngList.forEach((png, index) => {
                newAddedFileList.push(
                  makeUploadFile(png, `${png.name}.${index}.png`, /* mask */ undefined),
                );
              });
            }
            return;
          }
          newAddedFileList.push(makeUploadFile(f, lookupKey, mask));
        }); // add of addFileQueue.add
      }); // end of forEach
    await addFileQueue.run();

    try {
      const uploadFiles: UploadFile[] = truncateList(
        fileListUniqueByKey(
          [...uploadData, ...newAddedFileList],
          duplicateFileWarningMsg,
          item => item.key,
          true,
        ),
        capacity,
        filesTruncatedWarningMsg(limit),
        throwOnReachLimit,
      );
      if (sizeLimit) {
        const sizes = await Promise.all(
          uploadFiles.map(uploadFile => getImageSizeAsync(uploadFile.file)),
        );
        const overSizedImages: UploadFile[] = [];
        sizes.forEach((size, index) => {
          if (size > sizeLimit) {
            overSizedImages.push(uploadFiles[index]);
          }
        });
        if (overSizedImages.length > 0) {
          throw new OverSizeLimitException(
            t('There are files over size limit'),
            overSizedImages,
            sizeLimit,
          );
        }
      }
      return uploadFiles;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const deleteSegmentationImageFile = createAsyncThunk<
  UploadFile[],
  FileWithPath[],
  { state: ReduxState; dispatch: AppDispatch; rejectValue: void }
>('uploadState/deleteSegmentationImageFile', async (files: FileWithPath[], thunkAPI) => {
  const { getState } = thunkAPI;
  const { uploadData } = getState().uploadState;

  const deletedFileKeySet = new Set(files.map(file => getFileKey(file)));
  return uploadData.filter(uploadFile => !deletedFileKeySet.has(getFileKey(uploadFile.file)));
});

export const addSegmentationMaskFile = createAsyncThunk<
  {
    mask: SegmentationMask[];
    data: UploadFile[];
  },
  { files: FileWithPath[]; capacity?: number | null; limit?: number | null },
  { state: ReduxState; dispatch: AppDispatch; rejectValue: void }
>('uploadState/addSegmentationMaskFile', async ({ files, capacity, limit }, thunkAPI) => {
  const { getState } = thunkAPI;
  const { segmentationMasks, uploadData } = getState().uploadState;

  // Filter out dotfiles (.DS_Store, etc)
  const filteredFiles: FileWithPath[] = files.filter(f => !f.name.startsWith('.'));

  // Generate new segmentation mask array with base64 string
  const newSegmentationMasks: SegmentationMask[] = await Promise.all(
    filteredFiles.map(async file => {
      const base64String = (await blobToBase64(file)).split(',').pop() || '';
      return {
        key: getFileKey(file),
        file,
        base64String,
      };
    }),
  );

  // Generate segmentation mask lookup table from file key to mask object
  const maskLookup = newSegmentationMasks.reduce((lookup, mask) => {
    lookup[mask.key] = mask;
    return lookup;
  }, {} as Record<string, SegmentationMask>);

  // Iterate all images to associate with the new mask
  const newUploadData: UploadFile[] = uploadData.map(file => {
    // Intentionally to copy file.initialLabel to ensure immutability since we will directly
    // edit this attribute shortly
    const newFile: UploadFile = { ...file, initialLabel: { ...file.initialLabel } };
    const mask = maskLookup[newFile.key];

    if (!newFile.initialLabel) {
      newFile.initialLabel = {};
    }
    if (mask) {
      newFile.initialLabel.segMask = mask.base64String;
      newFile.initialLabel.unlabeledAsNothingToLabel = false;
    }

    return newFile;
  });

  return {
    mask: truncateList(
      segmentationMasks
        ? fileListUniqueByKey(
            [...segmentationMasks, ...newSegmentationMasks],
            duplicateFileWarningMsg,
            item => item.key,
            true,
          )
        : newSegmentationMasks,
      capacity,
      filesTruncatedWarningMsg(limit),
    ),
    data: newUploadData,
  };
});

export const deleteSegmentationMaskFile = createAsyncThunk<
  {
    mask: SegmentationMask[];
    data: UploadFile[];
  },
  FileWithPath[],
  { state: ReduxState; dispatch: AppDispatch; rejectValue: void }
>('uploadState/deleteSegmentationMaskFile', async (files: FileWithPath[], thunkAPI) => {
  const { getState } = thunkAPI;
  const { segmentationMasks, uploadData } = getState().uploadState;

  // If there is no mask, directly return an empty array
  if (!segmentationMasks || !segmentationMasks.length) {
    return {
      mask: [],
      data: uploadData,
    };
  }

  // Filter out dotfiles (.DS_Store, etc)
  const filteredFileKeys: Set<string> = new Set(
    files.filter(f => !f.name.startsWith('.')).map(file => getFileKey(file)),
  );

  const deletedMasks: SegmentationMask[] = [];
  const newSegmentationMasks: SegmentationMask[] = segmentationMasks.filter(mask => {
    if (filteredFileKeys.has(mask.key)) {
      deletedMasks.push(mask);
      return false;
    }
    return true;
  });

  // Generate deleted segmentation mask lookup table from file key to mask object
  const deletedMaskLookup = deletedMasks.reduce((lookup, mask) => {
    lookup[mask.key] = mask;
    return lookup;
  }, {} as Record<string, SegmentationMask>);

  // Iterate all images to deassociate with the deleted mask
  const newUploadData: UploadFile[] = uploadData.map(file => {
    // Intentionally to copy file.initialLabel to ensure immutability since we will directly
    // edit this attribute shortly
    const newFile: UploadFile = { ...file, initialLabel: { ...file.initialLabel } };
    const mask = deletedMaskLookup[newFile.key];

    if (!newFile.initialLabel) {
      newFile.initialLabel = {};
    }
    if (mask) {
      delete newFile.initialLabel.segMask;
      newFile.initialLabel.unlabeledAsNothingToLabel = false;
    }

    return newFile;
  });

  return {
    mask: newSegmentationMasks,
    data: newUploadData,
  };
});

export const addSegmentationDefectMapFile = createAsyncThunk<
  {
    defect: DefectMap;
    data: UploadFile[];
  },
  FileWithPath,
  { state: ReduxState; dispatch: AppDispatch; rejectValue: void }
>('uploadState/addSegmentationDefectMapFile', async (file: FileWithPath, thunkAPI) => {
  const { getState } = thunkAPI;
  const { uploadData } = getState().uploadState;

  const defectMap: DefectMap = {
    key: getFileKey(file),
    file,
    map: await getJsonContent(file),
  };

  if (!isSegDefectMapValid(defectMap.map)) {
    throw new Error(
      'Format error. Should be number keys and string values. e.g. { "1": "Broken" }',
    );
  }

  // Iterate all images to associate with the new defect map
  const newUploadData: UploadFile[] = uploadData.map(file => {
    // Intentionally to copy file.initialLabel to ensure immutability since we will directly
    // edit this attribute shortly
    const newFile: UploadFile = { ...file, initialLabel: { ...file.initialLabel } };

    if (!newFile.initialLabel) {
      newFile.initialLabel = {};
    }
    newFile.initialLabel.segDefectMap = JSON.stringify(defectMap.map);

    return newFile;
  });

  return {
    defect: defectMap,
    data: newUploadData,
  };
});

export const deleteSegmentationDefectMapFile = createAsyncThunk<
  {
    defect: DefectMap;
    data: UploadFile[];
  },
  void,
  { state: ReduxState; dispatch: AppDispatch; rejectValue: void }
>('uploadState/deleteSegmentationDefectMapFile', async (_, thunkAPI) => {
  const { getState } = thunkAPI;
  const { uploadData } = getState().uploadState;

  // Iterate all images to deassociate with the deleted mask
  const newUploadData: UploadFile[] = uploadData.map(file => {
    // Intentionally to copy file.initialLabel to ensure immutability since we will directly
    // edit this attribute shortly
    const newFile: UploadFile = { ...file, initialLabel: { ...file.initialLabel } };

    if (!newFile.initialLabel) {
      newFile.initialLabel = {};
    }
    delete newFile.initialLabel.segDefectMap;

    return newFile;
  });

  return {
    defect: null,
    data: newUploadData,
  };
});

export const setFileWithNothingToLabel = createAsyncThunk<
  UploadFile[],
  boolean,
  { state: ReduxState; dispatch: AppDispatch; rejectValue: void }
>('uploadState/setFileWithNothingToLabel', async (nothingToLabel: boolean, thunkAPI) => {
  const { getState } = thunkAPI;
  const { uploadData } = getState().uploadState;

  return uploadData.map(file => {
    // Intentionally to copy file.initialLabel to ensure immutability since we will directly
    // edit this attribute shortly
    const newFile: UploadFile = { ...file, initialLabel: { ...file.initialLabel } };

    if (!newFile.initialLabel) {
      newFile.initialLabel = {};
    }

    // Only update files without associated segmentation mask
    if (!newFile.initialLabel.segMask) {
      newFile.initialLabel.unlabeledAsNothingToLabel = nothingToLabel;
    }
    return newFile;
  });
});
