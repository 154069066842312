import { ExperimentType, LabelType } from '../types';

export const convertLabelTypeToExperimentType = (labelType: LabelType): ExperimentType => {
  switch (labelType) {
    case LabelType.BoundingBox:
      return ExperimentType.ObjectDetection;
    case LabelType.AnomalyDetection:
      return ExperimentType.AnomalyDetection;
    case LabelType.Classification:
      return ExperimentType.Classification;
    case LabelType.Segmentation:
      return ExperimentType.Segmentation;
    case LabelType.SegmentationInstantLearning:
      return ExperimentType.SegmentationInstantLearning;
    default:
      throw new Error('All label type should be mapped');
  }
};
