import { JOB_STATUS } from './status';
import LdapStrategy from 'passport-ldapauth';
import PassportGoogle from 'passport-google-oauth20';
import { ClientTrainingState, MetadataFormattedValue } from '.';

export type OrgId = number;
export type ProjectId = number;
export type DeviceId = string;
export type ReportDays = number;
export type ReportKind = string;
export type AccountId = string;
export type UserId = string;
export type PendingUserId = number;
export type UserName = string;
export type TaskId = number;
export type DatasetId = number;
export type MetadataId = number;
export type MediaId = number;
export type DefectId = number;
export type LabelId = number;
export type AnnotationId = string;
export type DateStringISO = string; // ex: 2019-09-26T23:56:05.402Z
export type DateString = string; // ex: 20190926
export type Index = number; // positive number > 0
export type DatasetVersionId = number;
export type FieldId = number;
export type DefectBookExampleId = number;
export type RegisteredModelId = string;
export type BooleanString = 'true' | 'false';
export type ModelId = string;

export enum FilterType {
  ColumnFilter = 'ColumnFilter',
  FieldFilter = 'FieldFilter',
}

export enum UserStatus {
  Confirming = 'CONFIRMING',
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Inactive = 'INACTIVE',
}

export enum UserRole {
  Owner = 'owner',
  Admin = 'admin',
  Collaborator = 'collaborator',
}

export enum ProjectRole {
  Owner = 'owner',
  Standard = 'standard',
  Labeler = 'labeler',
}

export enum MediaType {
  Image = 'image',
  Video = 'video',
  Text = 'text',
}

export enum MediaSource {
  User = 'user',
  Production = 'production_line',
  RunlivePrediction = 'runlive_prediction',
}

export enum AssocType {
  Defect = 'defect',
}

export enum MediaLevelLabel {
  OK = 'OK',
  NG = 'NG',
}

export enum LabelReviewStatus {
  NotReviewed = 'not_reviewed',
  /*
    not_applicable can mean both not review ready or that review is not applicable
    */
  NotApplicable = 'not_applicable',
  // not_selected means not accpeted/rejected labels during review
  NotSelected = 'not_selected',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export enum LabelStatus {
  Unlabeled = 'unlabeled',
  Labeled = 'labeled',
  Approved = 'approved',
}

export enum TaskPurpose {
  Training = 'training',
  BatchLabeling = 'batch_labeling',
  MultiLabeling = 'multi_labeling',
  LDB = 'live_defect_book',
}

export enum TaskAction {
  Cancel = 'cancel',
  AssignUsers = 'assign_users',
  ReviewLabeling = 'review_labeling',
}

export enum TaskStatus {
  Created = 'created',
  Unassigned = 'unassigned', // FIXME: temporal status used for batch labeling task
  Labeled = 'labelled', // FIXME: temporal status used for batch labeling task
  Assigned = 'assigned',
  InProgress = 'in_progress',
  Completed = 'completed',
  Approved = 'approved',
  UnderReview = 'under_review',
  Reviewed = 'reviewed',
  Canceled = 'canceled',
}

export enum UserTaskStatus {
  Assigned = 'assigned',
  Canceled = 'canceled',
  Completed = 'completed',
}

export enum LabelingType {
  MediaLevelClassification = 'media_level_classification',
  DefectClassification = 'defect_classification',
  DefectSegmentation = 'defect_segmentation',
  DefectBoundingBox = 'defect_bounding_box',
}

export enum AnnotationType {
  bndbox = 'bndbox',
  segmentation = 'segmentation',
  segmask = 'segmask', // deprecated, to be removed
  classification = 'classification',
  anomaly_detection = 'anomaly_detection',
}

/**
 * Project label type
 * ===
 * Values need to align with project table label_type column
 * packages/server-clef/src/database/migrations/20210818064118-project_label_type.js:8
 */
export enum LabelType {
  BoundingBox = 'bounding_box',
  Segmentation = 'segmentation',
  Classification = 'classification',
  AnomalyDetection = 'anomaly_detection',
  SegmentationInstantLearning = 'segmentation_instant_learning',
}

export const LabelTypeToAnnotationType: Record<LabelType, AnnotationType> = {
  [LabelType.BoundingBox]: AnnotationType.bndbox,
  [LabelType.Segmentation]: AnnotationType.segmentation,
  [LabelType.SegmentationInstantLearning]: AnnotationType.segmentation,
  [LabelType.Classification]: AnnotationType.classification,
  [LabelType.AnomalyDetection]: AnnotationType.anomaly_detection,
};

export enum ExperimentType {
  ObjectDetection = 'object_detection',
  Segmentation = 'segmentation',
  Classification = 'classification',
  AnomalyDetection = 'anomaly_detection',
  SegmentationInstantLearning = 'segmentation_instant_learning',
}

export enum DatasetType {
  Defect = 'defect',
  Training = 'training',
}

export enum MediaStatusType {
  Raw = 'raw',
  Approved = 'approved',
  InTask = 'in_task',
}

export enum Split {
  Train = 'train',
  Dev = 'dev',
  Test = 'test',
}

export interface MetadataBasic {
  id: MetadataId;
  orgId: OrgId;
  projectId: ProjectId;
  name: string;
  owner: string;
  type: MetadataType;
  predefinedChoices?: string[] | null;
  allowMultiple: boolean;
  valueFlexible: boolean;
}

export interface Metadata extends MetadataBasic {}

export interface VersionedMetadataBasic extends MetadataBasic {
  version: number;
}

export enum MetadataType {
  Number = 'number',
  Text = 'text',
  Boolean = 'boolean',
}

export enum MetadataObjectType {
  Media = 'media',
  Defect = 'defect',
}

export enum MetadataTypeExtra {
  Label = 'groundTruth',
  Prediction = 'prediction',
  DatasetContent = 'datasetContent',
  ProjectSplit = 'projectSplit',
  PostProcLabel = 'postProcLabel',
}

export enum MetadataFilterConditionType {
  ContainAny = 'Contains Any',
  EqualTo = 'Equals To',
  LargerThan = 'Larger Than',
  SmallerThan = 'Smaller Than',
  LargerOrEqualTo = 'Larger Than or Equals To',
  SmallerOrEqualTo = 'Smaller Or Equals To',
  NotEqualTo = 'Not Equals To',
  Between = 'Between',
  Before = 'Before',
  After = 'After',
}

export enum LabelingToolViewType {
  Label = 'label',
  Review = 'review',
}

export type MetadataValueType =
  | string
  | string[]
  | number
  | number[]
  | boolean
  | [number | null, number | null];

export interface MetadataFilter {
  id: FieldId;
  name: string;
  value: MetadataValueType | null;
  condition: MetadataFilterConditionType | null;
}

export interface ColumnFilter {
  objectTypeName: string;
  columnName: string;
  name: string;
  value: MetadataValueType | null;
  condition: MetadataFilterConditionType | null;
}

export enum OrganizationSegment {
  Work = 'Work',
  Academic = 'Academic',
  Personal = 'Personal',
  Empty = '',
}

export interface Organization {
  id: OrgId;
  name: string;
  bucket: string;
  ssoConfig: Saml2Config;
  sampleProjectIds: number[];
  settings?: Record<string, string | number | boolean>;
  creator?: AccountId;
  expirationDate?: DateStringISO;
  segment?: OrganizationSegment;
  // The user who created or update this subscription
  updatedByUserId?: string;
  subscriptionId?: number;
  latestSubscription?: Subscription;
}

export interface ActiveProject extends Project {
  canAccess?: boolean; // for old subscription plan, canAccess is undefined
}

export interface Project {
  id: ProjectId;
  orgId: OrgId;
  name: string;
  inviteOnly: boolean;
  allowedUsers: Array<UserId>;
  usersRoles?: Partial<UserProject>[];
  datasetId?: DatasetId;
  dataset?: Dataset;
  pendingUsers?: PendingUser[];
  importStatus: ProjectImportStatus;
  importInfo: ProjectImportInfo | null;
  labelType?: LabelType | null;
  modelDefaultMetricKey?: string;
  createdAt: string;
  coverMediaId: number | null;
  isDeleted?: boolean;
  active?: boolean;
}

// This is for displaying stats of each project of one orgnization
export interface ProjectStatsById {
  mediaCountByDatasetId: { [key: number]: number };
  labeledMediaCountByDatasetId: { [key: number]: number };
  modelCountByProjectId: { [key: ProjectId]: number };
}

export enum UserPermission {
  UploadData = 'upload_data',
  TrainModel = 'train_model',
  DeployModel = 'deploy_model',
  DirectLabel = 'direct_label',
}

export type UserProject = {
  id: number;
  orgId: number;
  userId: string;
  userName: string;
  projectId: number;
  role: ProjectRole;
  isOwner: boolean;
  // derived attribute, not stored in db
  projectRole: ProjectRole;
  permissions: UserPermission[];
  lastOpenedTime: string;
};

export interface User {
  id: UserId;
  orgId: OrgId;
  name: UserName;
  lastName: string;
  username: string;
  status?: UserStatus;
  email: string;
  bucket: string;
  userRole: UserRole;
  rank?: 'SENIOR' | string;
  apiKeyHash?: string;
  apiSecretHash?: string;
  ssoUser?: boolean;
  stripeUserId?: string;
  accountId?: AccountId;
  company?: string;
  subscriptions?: Subscription[];
  currentTime?: string;
  expirationDate?: DateStringISO;
  readonly?: boolean;
  internal?: boolean;
  snowflakeEnv?: {
    account?: string;
    database?: string;
    schema?: string;
    applicationName?: string;
    currentVersion?: string;
    installedVersion?: string;
    installerUrl?: string;
  } | null;
}

export type UserWithAppVersion = User & {
  appVersion?: string;
  intercomUserHash?: string;
  lndTier?: string;
  stripePublicKey?: string;
};

export interface UserGroup {
  activeCount: number;
  activeUsers: User[];
  pendingCount: number;
  pendingUsers: User[];
  totalCount: number;
}

export interface UserWithUserProject extends User {
  userProject: UserProject;
}

export interface ProjectWithUsers extends Project {
  users: UserWithUserProject[];
}

export interface AuthorizationInfo {
  projectId: number;
  datasetId: number;
  permissions?: UserPermission[];
  role?: ProjectRole;
  sampleOrgId?: number;
}

export interface PendingUser {
  id: number;
  email: string;
  orgId: OrgId;
  invitorId: UserId;
  userRole: UserRole;
  inviteCode?: string;
  accountId?: AccountId;
  internal?: boolean;
}

export interface PendingSettingsUser {
  id: number;
  email: string;
  orgId: OrgId;
  invitorId: UserId;
  userRole: UserRole;
  inviteCode?: string;
}

export interface Task {
  id: TaskId;
  orgId: OrgId;
  projectId: ProjectId;
  creatorId: UserId | null;
  dataSelector: string;
  taskName: string;
  mediaCount: number;
  completionTime: DateStringISO;
  creationTime: DateStringISO;
  updatedAt: DateStringISO;
  labelConfig?: object;
  purpose: TaskPurpose;
  status: TaskStatus;
  selectorConfig?: object;
  defectSnapshot: DefectSnapshot[];
  assignees: TaskAssignee[];
  labelingType: Array<LabelingType>;
  extra: TaskExtra;
  project?: Project;
  transferLabel?: boolean;
  actions: Array<TaskAction>;
  iou?: TaskIoU;
  mediaIds?: MediaId[];
  closed?: Boolean;
  // The user who created or update this subscription
  updatedByUserId?: string;
}

export interface UserTask {
  id: number;
  orgId: OrgId;
  taskId: TaskId;
  status: UserTaskStatus;
  role: UserRole;
  userId: string;
  createdAt: DateStringISO | null;
  updatedAt: DateStringISO | null;
}

export type TaskWithAssignees = Task & {
  assignees: TaskAssignee[];
};

export interface UserTaskStats {
  userId: UserId;
  labeledMediaCount: number;
}

export interface MyTaskStats {
  totalMediaCount: number;
  usersStats: UserTaskStats[];
}

export type TaskWithStats = Task & {
  stats: MyTaskStats;
};

export type LabelingTaskRow = Task & {
  stats: MyTaskStats;
};

export interface TaskAssignee {
  id: number;
  orgId?: OrgId;
  taskId?: TaskId;
  userId: UserId;
  status: UserTaskStatus;
  role?: UserRole;
  userAssoc: Pick<User, 'id' | 'name' | 'email'>;
  createdAt: DateStringISO | null;
  updatedAt: DateStringISO | null;
}

export interface TaskExtra {
  randomizeMedia?: boolean;
  consensusGroundTruth?: 'consensus' | 'defect_class';
  templatePath?: string;
  workerTeam?: string;
  numberOfWorkers?: string;
  taskTimeLimitInHours?: string;

  // for multi labeler labeling task
  numberOfLabelerPerMedia?: number;
}

export interface TaskIoU {
  iou: number;
  mediaToIoU: Record<MediaId, number>;
}

export interface Dataset {
  id: DatasetId;
  orgId: OrgId;
  projectId: ProjectId;
  name: string;
  creationTime: DateStringISO;
  description: string;
  mediaCount?: number;
  detail?: boolean;
  datasetVersions?: DatasetVersion[];
}

export interface DatasetContent {
  datasetId: DatasetId;
  mediaId: MediaId;
  labelId: LabelId;
  orgId: OrgId;
  mediaStatus: MediaStatusType;
  splitSet: number | null;
  tagIds: number[] | null;
  metadata: MetadataFormattedValue | null | undefined;
}

export interface VersionedDatasetContent {
  datasetId: DatasetId;
  mediaId: MediaId;
  labelId: LabelId;
  orgId: OrgId;
  mediaStatus: MediaStatusType;
  splitSet: number | null;
  version: number;
  tagIds: number[] | null;
  metadata: MetadataFormattedValue | null;
}

export interface ProjectSplit {
  id: number;
  orgId: OrgId;
  projectId: ProjectId;
  splitSetName: string;
  description: string;
}

export interface MediaMetrics {
  id: number;
  orgId: number;
  projectId: number;
  modelId: string;
  mediaId: number;
  groundTruthLabelId: number;
  predictionLabelId: number;
  tps?: number[];
  fps?: number[];
  tns?: number[];
  fns?: number[];
  mcs?: number[];
  confusionMatrixPath?: string | null;
}

export interface PascalVOC {
  pascalVocPath?: string;
  batchJobId?: string;
}
export interface DatasetVersion {
  id: DatasetVersionId;
  datasetId: DatasetId;
  version: number;
  csvPath?: string;
  orgId: OrgId;
  creationTime: DateStringISO;
  defectMapPath: string;
  name: string;
  defectBookSnapshot: DefectSnapshot[];
  sourceCsvPaths?: string[];
  count: number;
  pascalVoc: PascalVOC | null;
  defectMap?: DefectMapWithDetails | null;
  fastEasyExport?: boolean | null;
  tagSnapshot?: TagSnapshot | null;
  metadataSnapshot?: Record<string, MetadataBasic>;
  details?: string;
  creator?: string;
  isDeleted?: boolean;
  modelsCount?: number;
  description?: string;
}

export type Position = { x: number; y: number };
export type Dimensions = {
  width: number;
  height: number;
  format?: string;
  orientation?: number;
};
export type Box = Position & Dimensions;

export type MediaProperties = Dimensions;

export interface Media {
  id: MediaId;
  orgId: OrgId;
  name: string;
  path: string;
  mediaType: MediaType;
  srcType: MediaSource;
  srcName: string;
  properties?: MediaProperties | null;
  projectId?: ProjectId;
  url?: string;
  mediaStatus?: MediaStatusType;
  contentType?: string;
  md5?: string;
  size?: number;
  uploadTime?: DateStringISO;
  thumbnails?: Array<string>; //e.g. [100x100, 200x200, 300x300]
  // The user who created or update this subscription
  updatedByUserId?: string;
}

export type MediaWithDatasetContent = Media & {
  datasetContent?: [
    {
      media_status: MediaStatusType;
    },
  ];
};

export interface MediaData {
  mediaId: number;
  labelId: number;
}

export interface MediaDetails extends Media {
  label: Label | null;
  split?: number | null;
  tagIds?: number[] | null;
  metadata?: MetadataFormattedValue | null;
}

export interface MediaDetailsWithPrediction extends MediaDetails {
  predictionLabel: Label | null;
  hasChangedSinceSnapshot?: boolean;
  hasBeenDeletedSinceSnapshot?: boolean;
}

export type MediaSpec = Omit<Media, 'id' | 'url'>;
export type MediaCreateSpec = Omit<MediaSpec, 'orgId' | 'srcType' | 'srcName'> & {
  metadata: object;
  datasetId?: number;
  split?: string | null;
  initialLabel?: {
    classification?: string;
    objectDetection?: string;
    segMask?: string;
    segDefectMap?: string;
    unlabeledAsNothingToLabel?: boolean;
  };
};

export interface TextFile {
  url: string;
  projectId: ProjectId;
  mediaType: MediaType;
  labelIds?: number[];
  name?: string;
  srcType?: MediaSource;
}

export enum UploadType {
  Dataset = 'dataset',
  DefectBook = 'defect_book',
  Prediction = 'prediction',
}

export type MetadataObjectValue = string | (string | number)[] | number | boolean;

export interface DefectSnapshot {
  id: number;
  defect_class: string; // name of the data class
  project_name: Project['name'];
  children?: DefectSnapshot[];
}

export type LineAnnotation = Readonly<{
  points: number[];
  color: string;
  strokeWidth: number;
  opacity: number;
  // for backwards compatibility
  version?: number;
}>;

export type BoxAnnotation = Readonly<{
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  opacity?: number;
  dashed?: boolean;
  tag?: string;
}>;

export type TextAnnotation = Readonly<{
  x: number;
  y: number;
  text: string;
  color: string;
  fontSize: number;
}>;

export type BitMapAnnotation = Readonly<
  SegmentationAnnotationData & {
    opacity?: number;
    color: string;
    key: string;
    enableContour?: boolean;
  }
>;

export type PureCanvasAnnotation = Readonly<{
  x: number;
  y: number;
  canvas: OffscreenCanvas;
  opacity?: number;
  height?: number;
  width?: number;
  onHover?: (isHover: boolean) => void;
  color?: string;
  enableContour?: boolean;
}>;

export interface DefectSection {
  title: string;
  description: string;
  examples: DefectBookExampleId[];
}

export interface Defect {
  id: DefectId;
  name: string;
  descriptionText?: string | null;
  orgId: OrgId;
  projectId: ProjectId;
  // deprecated
  skipped?: boolean;
  // deprecated
  datasets?: Dataset[]; // HLP/LDB datasets
  // deprecated
  sections?: DefectSection[];
  createdAt: DateStringISO;
  updatedAt: DateStringISO | null;
  color?: string | null;
  defect_book_example_ids?: number[];
  indexId?: number;
  isArchived?: boolean;
}

export interface VersionedDefect {
  id: DefectId;
  orgId: OrgId;
  projectId: ProjectId;
  name: string;
  indexId?: number;
  color?: string;
  version: number;
  descriptionText?: string;
  defect_book_example_ids?: number[];
  createdAt: DateStringISO;
  updatedAt: DateStringISO | null;
}

export interface AlertRecipient {
  email: string;
  user_id: string;
}

export interface SCAppParams {
  project_id?: number;
  bundle_id?: string;
  cl_app_version?: string;
}

export interface SCAppCreateParams {
  code?: number;
  message?: string;
}

export interface SCAppDownloadParams {
  sc_bundle_zip_url?: string;
}

export type DefectBookInfo = Readonly<{
  projectTitle: string;
  projectBackground: string;
  termDescription: string;
  termExamples: DefectBookExample[];
  defects: Defect[];
}>;

export type DefectSpec = Partial<Defect>;

export interface DefectStatEntry {
  defectId: DefectId;
  reviewStatus: LabelReviewStatus | null; //null means label doesn't exist
  count: number;
}

export interface DefectBookMetrics {
  totalCount: number;
  countWithDescriptions: number;
  lastConsensusMeasuredTime: string;
}

export interface DefectBookExample {
  id: DefectBookExampleId;
  orgId: OrgId;
  mediaId: MediaId;
  note: string | null;
  annotations: DefectBookExampleAnnotation;
}

export interface WipDefectData {
  name: string;
  description: string;
  color: string;
  examples?: DefectBookExample[];
  // if there are update applied
  drafted: boolean;
}

export type DefectBookExampleSpec = Partial<DefectBookExample>;

export type DefectBookExampleData = Pick<DefectBookExample, 'mediaId' | 'annotations' | 'note'>;

export interface DefectBookExampleAnnotation {
  textAnnotations: TextAnnotation[];
  boxAnnotations: BoxAnnotation[];
  lineAnnotations: LineAnnotation[];
}

export interface DefectBookOverview {
  orgId: OrgId;
  projectId: ProjectId;
  defectBookInfo?: DefectBookOverviewInfo;
}

export interface DefectBookOverviewInfo {
  // deprecated
  projectTitle: string | null;
  projectBackground: string | null;
  // deprecated
  termDescription: string | null;
  termExamples: DefectBookExampleId[];
}

export type DefectBookOverviewSpec = Partial<DefectBookOverview>;

export type DefectMapWithDetails = Record<number, { defectId?: number; name: string }>;
export type DefectMap = Record<DefectId, DefectData>;

// extension of Defect with reference back to its parent category
export interface DefectData extends Defect {
  category: string;
}

export type DefectColors = Record<DefectId, string>; // string in #DEADBEEF format

/**
 * Used for type of creating labels, the annotations
 * are given without id or label ids
 */
export interface CreateLabelSpec extends Partial<Omit<Label, 'annotations'>> {
  annotations?: AnnotationBase[];
}

// update, has to have the primary key
export interface UpdateLabelSpec extends Partial<Omit<Label, 'annotations'>> {
  id: number;
  annotations?: AnnotationBase[];
}
export interface RawLabel {
  id: LabelId;
  orgId: OrgId;
  mediaId: Media['id'];
  labelerName: User['id'];
  labelTime: string;
  mediaLevelLabel?: MediaLevelLabel | null;
  bndboxNumber: number;
  segNumber: number;
  defectClassifNumber: number;
  defectDistribution: Record<number, number> | null;
  createdAt: DateStringISO;
  updatedAt?: DateStringISO;
  xmlPath?: string | null;
  segImgPath?: string | null;
  rawSegImgPath?: string | null;
  source?: LabelSource;
  modelId?: string | null;
  mediaLevelScore?: number | null;
}
export interface Label extends RawLabel {
  annotations?: AnnotationBase[] | Annotation[];
  annotationSize?: number;
  datasetContent?: {
    mediaStatus: MediaStatusType;
  };
  taskId?: TaskId | null;
  reviewerId?: User['id'] | null;
  reviewStatus?: LabelReviewStatus;
  reviewNotes?: string;
  agreementScore?: number | null;
  reAssigned?: boolean;
  heatmapPath?: string | null;
  postProcLabel?: Pick<PostProcLabel, 'filteredMaskPath' | 'classString'>;
  // The user who created or update this subscription
  updatedByUserId?: string;
}

export interface LabelWithDefectList extends Label {
  defectList: DefectId[];
}

export type LabelSpec = Partial<Label>;

export interface TaskLabel {
  taskId: TaskId;
  labelId: LabelId;
  orgId: OrgId;
  reviewerId?: User['id'] | null;
  reviewStatus: LabelReviewStatus;
  reviewNotes?: string;
  agreementScore?: number | null;
  createdAt?: DateStringISO;
  updatedAt?: DateStringISO;
  reAssigned?: boolean;
}

export interface LabelWithTask extends Label {
  taskLabel?: TaskLabel;
}

export interface AnnotationClass {
  defectId: DefectId;
  assocType?: AssocType;
}

export type RangeBox = {
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
};

export interface AnnotationInstance {
  mediaId: number;
  maxThreshold?: number;
  minThreshold?: number;
  groundTruthAnnotation: { id: number; defectId: number; rangeBox: RangeBox } | null;
  predictionAnnotation: {
    id: number;
    defectId: number;
    rangeBox: RangeBox;
    confidence: number;
  } | null;
}

export interface SegmentationAnnotationData {
  rangeBox: RangeBox;
  bitMap: string;
}

export interface BoundingBoxAnnotationData {
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
}

export interface ClassificationAnnotationData {}

export interface PNGAnnotationData {
  data: string;
}

export type SelectedAnnotationType = Record<
  string,
  { defect: AnnotationClass; annotationType: AnnotationType }
>;

export interface AnnotationBase {
  defectId: number;
  annotationType: AnnotationType;
  dataSchemaVersion: number;
  confidence?: number;
  segmentationBitmapEncoded?: string;
  rangeBox?: RangeBox;
  updatedAt?: string;
  createdAt?: string;
}
export interface AnnotationWithoutId extends AnnotationBase {
  labelId?: number;
  orgId?: number;
}

export interface Annotation extends AnnotationWithoutId {
  id: number;
}
export interface AnnotationOldFormat extends AnnotationClass {
  dataVersion: string;
  annotationData?:
    | BoundingBoxAnnotationData
    | SegmentationAnnotationData
    | ClassificationAnnotationData;
  annotationType: AnnotationType;
  encoding?: {
    algo: string;
    options: {
      map: {
        [key: string]: string;
      };
    };
  };
  confidence?: number;
}

export type AnnotationMap = Record<string, AnnotationOldFormat>;

export type GroundTruthAnnotation = {
  mediaId: number;
  gtDefectId: number;
  gtRangeBox: RangeBox;
  gtId: number;
};

export type PredictionAnnotation = {
  mediaId: number;
  predDefectId: number;
  predRangeBox: RangeBox;
  predConfidence: number;
  predId: number;
};

export interface ObjectPredefinedChoicesType {
  id: number | string; // the value write to search query for example defectId
  label: string; // the value render to UI for example defect name
}
export interface SearchableObject {
  name: string;
  type: MetadataType;
  predefinedChoices?: string[] | ObjectPredefinedChoicesType[] | null;
  allowMultiple: boolean;
  valueFlexible: boolean;
  filterType?: FilterType; // if undefined, means field filter type
  isTimestamp?: boolean;
}

export interface SearchableColumn extends SearchableObject {
  objectTypeName: string;
  columnName: string;
}

export interface MetadataField extends SearchableObject {
  id: FieldId;
}

export interface MetadataFieldItem {
  id: string;
  label: string;
  field: MetadataField;
  value: string | number;
}

export interface SearchColumnResult {
  [id: string]: {
    [id: string]: {
      type: MetadataType;
      predefinedChoicesType: string[] | null;
      allowMultiple: boolean;
      name: string;
      isTimestamp?: boolean;
    };
  };
}

export interface ClassificationStats {
  classification_name: string;
  count: number;
  itemStyle: object;
}

export interface DatasetVersionRow {
  id: number;
  data: Array<number | string>;
}

export interface CrossDefectStat {
  trueDefectId: DefectId;
  defectId: DefectId;
  score: number;
  mediaIds: MediaId[];
}

export interface WithinDefectStat {
  trueDefectId: DefectId;
  score: number;
  mediaIds: MediaId[];
  counts: { OK: number; NG: number };
}

export interface TaskResult {
  labels: Label[];
  userTasks: UserTask[];
  trueDefects?: Record<MediaId, DefectId[]>;
  stats: {
    cross: CrossDefectStat[];
    within: WithinDefectStat[];
  };
}
export interface SortFieldInfo {
  sortType: string;
  sortValue: string;
  sortOrder?: 'asc' | 'desc';
  orderLabel?: string;
  label: string;
}

export interface PaginationOptions {
  limit: number;
  offset: number;
}

export interface SortOptions extends Partial<SortFieldInfo>, Partial<PaginationOptions> {}

export enum TASK_MEDIA_ASSIGNMENT_STATUS {
  REQUESTED = 'requested',
  ASSIGNED = 'assigned',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
}

export interface TaskMediaAssignment {
  id: number;
  userTaskId: UserTask['id'];
  taskId: TaskId;
  orgId: OrgId;
  mediaId: Media['id'];
  status: TASK_MEDIA_ASSIGNMENT_STATUS;
}

export interface TaskMediaStats {
  notAssigned: number;
  assigned: number;
  labeled: number;
  rejected: number;
  approved: number;
}

export interface UserTaskMediaStats {
  totalMediaCount: number;
  labeledByMe: number;
  ableToFetch: number;
}

export function isDefined<T>(arg: T | undefined | boolean | string | number): arg is T {
  return Boolean(arg);
}

export type TaskSpec = Omit<Partial<Task>, 'defectSnapshot' | 'assignees'> & {
  defectSnapshot: Partial<DefectSnapshot>[];
  datasetIds?: DatasetId[];
  seniorUserId?: UserId;
  assignees?: Partial<TaskAssignee>[];
  taskMediaAssignmentIds?: MediaId[];
};

export type SplitStatisticType = Record<string, number>;
export type DefectSplitStatisticType = Record<string, Record<string, number>>;

export enum FilterOptionValueType {
  NUMBER = 'number_operation',
  PREDEFINED_CHOICES = 'pre_defined_choices',
  RAW_TEXT = 'raw_text',
  SINGLE_SELECT = 'single_select',
}

export type FilterOperations =
  | '='
  | '>'
  | '<'
  | '>='
  | '<='
  | '!='
  | 'BETWEEN'
  | 'IS'
  | 'CONTAINS_ANY'
  | 'LIKE'
  | 'NOT_CONTAIN_ANY';

export interface FilterOptionType {
  filterType: 'column' | 'field';
  filterName: string;
  valueType: FilterOptionValueType;
  value?: Record<string, string | number | boolean>;
  //display in data browser as default filter option
  displayDefault?: boolean;
  // if filedType === 'field' should also have fieldId
  fieldId?: number;
  operations: FilterOperations[];
}

export enum FilterOptionName {
  MediaStatus = 'Media status',
  MediaName = 'Media name',
  GroundTruthLabels = 'Ground truth labels',
  GroundTruthOkNg = 'Ground truth OK/NG',
  PredictionLabels = 'Prediction labels',
  PredictionOkNg = 'Prediction OK/NG',
  Labeler = 'Labeler',
  MediaId = 'Media id',
  Split = 'Split',
  Tag = 'Tag',
}

// temporary solution, mapping from filter option name to the actual columnfilter name
// which need to add in selectMediaOption for applying filters.
export const FilterOptionNameToColumnTableFilterName = {
  [FilterOptionName.Labeler]: {
    table: MetadataTypeExtra.Label,
    col: 'labeler',
  },
  [FilterOptionName.GroundTruthLabels]: {
    table: MetadataTypeExtra.Label,
    col: 'defect',
  },
  [FilterOptionName.GroundTruthOkNg]: {
    table: MetadataTypeExtra.Label,
    col: 'mediaLevelLabel',
  },
  [FilterOptionName.PredictionLabels]: {
    table: MetadataTypeExtra.Prediction,
    col: 'defect',
  },
  [FilterOptionName.PredictionOkNg]: {
    table: MetadataTypeExtra.Prediction,
    col: 'mediaLevelLabel',
  },
  [FilterOptionName.MediaStatus]: {
    table: MetadataTypeExtra.DatasetContent,
    col: 'mediaStatus',
  },
  [FilterOptionName.MediaName]: {
    table: MetadataObjectType.Media,
    col: 'name',
  },
  [FilterOptionName.MediaId]: {
    table: MetadataObjectType.Media,
    col: 'mediaId',
  },
  [FilterOptionName.Split]: {
    table: MetadataTypeExtra.DatasetContent,
    col: 'splitSet',
  },
  [FilterOptionName.Tag]: {
    table: MetadataTypeExtra.DatasetContent,
    col: 'tagIds',
  },
};

export enum DatasetGroupOptions {
  PREDICTION_MEDIA_LEVEL_LABEL = 'predictionMediaLevelLabel',
  GROUND_TRUTH_MEDIA_LEVEL_LABEL = 'groundTruthMediaLevelLabel',
  MEDIA_STATUS = 'mediaStatus',
  BNDBOX_NUMBER = 'bndboxNumber',
  SEG_NUMBER = 'segNumber',
  LABELER = 'labeler',
  REVIEWER = 'reviewer',
  DEFECT_COUNT = 'defectCount',
  DEFECT_TYPE = 'defectType',
  DEFECT_DISTRIBUTION = 'defectDistribution',
  // only support split metadata now, need to support some other metadata later
  SPLIT = 'split',
}

export enum DatasetGroupValueFields {
  PREDICTION_MEDIA_LEVEL_LABEL = 'media_level_label',
  GROUND_TRUTH_MEDIA_LEVEL_LABEL = 'media_level_label',
  MEDIA_STATUS = 'media_status',
  SPLIT_SET = 'split_set_name',
  BNDBOX_NUMBER = 'bndbox_number',
  SEG_NUMBER = 'seg_number',
  LABELER = 'labeler_name',
  REVIEWER = 'reviewer_id',
  DEFECT_COUNT = 'defect_count',
  DEFECT_TYPE = 'defect_id',
  DEFECT_DISTRIBUTION = 'defect_distribution',
}

// The as for group by to use, because both prediction
// and ground truth are label join datasetContent table
// directly use column will have ambiguous, we need to declare
// the as name here so it will generate query like
// select prediction.media_level_label as prediction_media_level_label
export enum LabelAsName {
  PredictionOKNGName = `prediction_media_level_label`,
  GroundTruthOKNGName = `ground_truth_media_level_label`,
  GroundTruthDefectDistribution = `ground_truth_defect_distribution`,
  GroundTruthBndboxNumber = `ground_truth_bndbox_number`,
  GroundTruthLabeler = `ground_truth_labeler_name`,
  GroundTruthReviewer = `ground_truth_reviewer`,
  GroundTruthDefectId = `defect_id`,
}

export type GroupByCondition = { col: DatasetGroupOptions[]; metadata: MetadataId[] };

export enum ColumnsToReturn {
  MEDIA_ID = 'id',
  ORG_ID = 'orgId',
  MEDIA_TYPE = 'mediaType',
  PATH = 'path',
  SRC_TYPE = 'srcType',
  SRC_NAME = 'srcName',
  PROPERTIES = 'properties',
  NAME = 'name',
  UPLOAD_TIME = 'uploadTime',
  MEDIA_STATUS = 'mediaStatus',
  DEFECT_DISTRIBUTION = 'defectDistribution',
  LABEL_ID = 'labelId',
  METADATA = 'metadata',
  TAG_IDS = 'tagIds',
}

/***
 * this is the parameter we get with search
 * the key is JSON.stringsify(a defect distribution object)
 * the value is splitTag to its count object
 * example: {
 *  '{"1200": 1, "1201": 2}': {"train": 1, "dev": 1},
 *  '{"1200": 1}': {"train": 2, "dev": 1},
 *  '{"1201": 2}': {"train": 1, "dev": 3, "test": 1},
 *  'null': {"train": 1, "dev": 1}
 * }
 *  */

export type DefectDistributionToAssignSplitMapping = Record<string, Record<string, number>>;

/**
 * This project status is clone project status, created means finish cloning,
 * failed means failed cloning
 */
export enum ProjectImportStatus {
  CREATED = 'created',
  IMPORTING = 'importing',
  FAILED = 'failed',
}

export type ProjectImportInfo = {
  message?: string;
};

// media details from consensus review task
export type MediaDetailsForLabelingReview = Media & {
  labels: Label[];
  bestLabelId: number;
  reviewStatus: LabelReviewStatus;
  overallAgreementScore?: number;
};

export type LabelingTaskReviewResult = {
  [mediaId: number]: {
    reviewStatus: LabelReviewStatus;
    note: string;
    selectedLabel?: number;
    editedLabel?: boolean;
  };
};

export type CloudParams = {
  project_id: ProjectId;
  num_instances: number;
  timeout_minutes?: number;
  api_key: string;
  api_secret: string;
};

export enum SignedUrlType {
  Media = 'media',
  Label = 'label',
}

export type UploadParameters = {
  url: string;
  s3url: string;
};

export type UpsertLabelsParameters = {
  projectId: number;
  annotations: Annotation[] | AnnotationWithoutId[];
  mediaId: MediaId;
  mediaLevelLabel?: MediaLevelLabel;
  labelId?: number;
  source?: LabelSource;
  modelId?: string; // TODO: @Louie Convert to required in next release
  mediaName?: string;
};

export interface ExportJob {
  id: string;
  orgId: number;
  type: string;
  status: JOB_STATUS;
  progress: number;
  sourceType: string;
  sourceId: number;
  extra: object | null;
}

export type UserSignUpData = {
  email: User['email'];
  password: string;
  name: User['name'];
  lastname: User['lastName'];
  token: string;
  activationCode?: string;
};

export type SSOType = 'SAML2' | 'LDAP';
export interface SSOConfig {
  type: SSOType;
  config: Saml2Config | LDAPConfig;
  mapping: SSOAttributesMapping;
}

export interface SSOAttributesMapping {
  // the attributes name in saml assertion which mapping to ours db user columns
  email: string;
  lastName: string;
  name: string;
}
export interface Saml2Config {
  cert: string;
  issuer: string;
  entryPoint: string;
  callbackUrl: string;
  attributesMappingRules: SSOAttributesMapping;
  signatureAlgorithm?: 'sha1' | 'sha256' | 'sha512';
}

export interface LDAPConfig extends LdapStrategy.Options {}
export interface GoogleOAuth2Config extends PassportGoogle.StrategyOptionsWithRequest {}

export enum LabelSource {
  Labeling = 'from_labeling',
  Prediction = 'from_prediction',
  PredictionIE = 'from_inference_executor',
  Upload = 'from_upload',
  DirectLabeling = 'from_direct_label',
  LabelingReviewEdit = 'labeling_review_edit',
  LabelSuggestion = 'from_label_suggestion',
  LabelAssist = 'from_label_assist',
}

export type CloudwatchMetric = {
  projectId?: number;
  currentMonthDuration: number;
  lastMonthDuration: number;
};

export interface LabelWithAnnotationList extends Exclude<Label, 'annotation'> {
  annotationList: AnnotationWithoutId[];
}

// From https://landingai.atlassian.net/wiki/spaces/MI/pages/2018344998/Introduce+more+granular+training+job+status
export enum ModelStatus {
  Created = 'CREATED',
  Starting = 'STARTING',
  Training = 'TRAINING',
  Evaluating = 'EVALUATING',
  Publishing = 'PUBLISHING',
  Succeed = 'SUCCEEDED',
  Failed = 'FAILED',
  Saved = 'SAVED',
  /** @deprecated We will remove this feature. No further use of STOPPED status */
  Stopped = 'STOPPED',
  // User manually ended the training job
  // Difference between TERMINATED and STOPPED:
  //   - STOPPED: The training job will still be running and published
  //   - TERMINATED: The training job will be stop running early
  Terminated = 'TERMINATED',
  // Legacy job status only used by the old flow
  LegacyRunning = 'RUNNING',
  // Special status for VP
  /** @deprecated will be removed */
  FirstBatch = 'FIRSTBATCH',
  /** @deprecated will be removed */
  ALLBatches = 'ALLBATCHES',
}

export interface RegisteredModel {
  id: RegisteredModelId;
  orgId: number;
  projectId: number;
  confidence?: number | null;
  datasetVersionId?: number | null;
  status?: ModelStatus | null;
  modelName?: string | null;
  metricsReady?: boolean;
  predictionCount?: number;
  createdAt?: DateStringISO | null;
  updatedAt?: DateStringISO | null;
  // The user who created or update this subscription
  updatedByUserId?: string;
  isDeleted?: boolean | null;
  bundleOperations?: { [threshold: number]: { isFav?: boolean; isDeleted?: boolean } | undefined };
  details?: string | null;
  trainModelEvaluationReportId?: number | null;
  devModelEvaluationReportId?: number | null;
  testModelEvaluationReportId?: number | null;
}

export interface GroundTruthPredictionAnnotationMapping {
  gtAnnotationId?: number | null;
  predAnnotationId?: number | null;
  orgId: number;
  projectId: number;
  mediaId: number;
  gtDefectId?: number | null;
  predDefectId?: number | null;
  minThreshold: number;
  maxThreshold: number;
  modelId: string;
}

export interface BoundingBoxPrediction {
  mediaId: number;
  mediaLevelScore: number | null;
  label: {
    defectId: number;
    score: number;
    coordinates: {
      xmin: number;
      xmax: number;
      ymin: number;
      ymax: number;
    };
  }[];
  metrics: {
    tps: number[];
    fps: number[];
    tns: number[];
    fns: number[];
    mcs?: number[];
  } | null;
  classLevelMatrixPath?: string | null;
}

export interface ClassificationPrediction {
  mediaId: number;
  mediaLevelScore: number | null;
  label: {
    defectId: number;
    score: number;
  };
  heatmapPath?: string | null;
}

export interface SegmentationPrediction {
  mediaId: number;
  mediaLevelScore: number | null;
  segS3Path: string;
  metrics: {
    tps: number[];
    fps: number[];
    tns: number[];
    fns: number[];
    mcs?: number[];
  } | null;
  classLevelMatrixPath?: string | null;
}

export enum ActionsPipelineResultStep {
  SegMaskNoiseFilter = 'seg-mask-noise-filter', // base64-encoded PNG seg mask (similar to prediction)
  SegMaskClassification = 'seg-mask-classification', // user-defined label string
}

export type ActionsPipelineResult = {
  step: ActionsPipelineResultStep;
  result: string;
};

export type ModelStatusPollingParams = {
  previousModelStatus: ModelStatus | null;
  previousMetricsReady?: boolean;
};

export interface SegmentationInstantLearningPrediction extends SegmentationPrediction {
  // raw result
  actionsPipelineResult?: ActionsPipelineResult[] | Array<Array<string>>;
  // parsed result, only return postProcLabel when returning to frontend
  postProcLabel?: Pick<PostProcLabel, 'filteredMaskPath' | 'classString'>;
}

export type InferenceExecutorResult = Partial<
  | ClassificationPrediction
  | BoundingBoxPrediction
  | SegmentationPrediction
  | SegmentationInstantLearningPrediction
>;

export type ConfusionMatrixPerThreshold = {
  truePositives: number[];
  falsePositives: number[];
  trueNegatives: number[];
  falseNegatives: number[];
  misclassified?: number[];
};

export type ClassificationConfusionMatrix = {
  [actualDefectId: number]: {
    [predictedDefectId: number]: number;
  };
};

export type ConfusionMatrixCountByMediaId = Array<{
  mediaId: MediaId;
  incorrectCount: number;
  correctCount: number;
}>;

export type ObjectInstancePairing = {
  gtDefectId: number | null;
  predDefectId: number | null;
  count: number;
};
// for each threshold, the result is an array of objects
export type InstanceParingForOneThreshold = ObjectInstancePairing[];

export type PerformanceMetrics = {
  // these performances are aggregated over bounding boxes / pixels / classification
  performance: number[];
  performancePerSplit?: { [split: string]: number[] };
  // these performances are aggregated over media
  binarizedPerformance?: number[] | null;
  binarizedPerformancePerSplit?: { [split: string]: number[] } | null;
  version?: string;
  // version 1, confusion matrix is inside an object
  // confusion matrix
  confusionMatrix?: ConfusionMatrixPerThreshold | ClassificationConfusionMatrix;
  confusionMatrixPerSplit?: {
    [split: string]: ConfusionMatrixPerThreshold | ClassificationConfusionMatrix;
  };
  // object detection instance view only
  instanceParing?: InstanceParingForOneThreshold[];
  instanceParingConfusionMatrix?: ConfusionMatrixPerThreshold;
  binarizedConfusionMatrix?: ConfusionMatrixPerThreshold;
  binarizedConfusionMatrixPerSplit?: { [split: string]: ConfusionMatrixPerThreshold };
  // end of version 1
  // version 0, for backward compatibility
  truePositives?: number[];
  falsePositives?: number[];
  trueNegatives?: number[];
  falseNegatives?: number[];
  misclassified?: number[];
  // end of version 0
  // best confidence threshold
  bestConfidenceThreshold: number | null;
};

export interface ProjectModelInfo {
  modelDefaultMetricKey?: string | null;
  customTrainingConfig?: ClientTrainingState;
}

export interface CurrentModelInfo {
  id?: string;
  modelName?: string | null;
  createdAt?: DateStringISO | null;
  versionedDatasetContentId?: number;
  datasetVersionId?: number;
  modelDefaultMetricKey?: string | null;
  customTrainingConfig?: ClientTrainingState;
  confidence?: number;
  status?: ModelStatus | null;
}

export interface MediaStatsAPIResponse {
  count: number;
  // TODO: Need to figure a smart way to link this to DatasetGroupValueFields
  media_status?: MediaStatusType;
  defect_id?: number | null;
  split?: string | null;
  defect_distribution?: { [defectId: number]: number } | null;
  [LabelAsName.GroundTruthOKNGName]?: string;
  [LabelAsName.PredictionOKNGName]?: string;
}

export type MediaStatsResponse = {
  count: number;
  split?: string | null;
  media_status?: MediaStatusType;
  [LabelAsName.GroundTruthDefectId]?: number | string | null;
  [LabelAsName.GroundTruthDefectDistribution]?: { [defectId: number]: number };
  [LabelAsName.GroundTruthOKNGName]?: MediaLevelLabel;
  [LabelAsName.PredictionOKNGName]?: MediaLevelLabel;
}[];

export type ReviewType = 'reviewed' | 'review_ready';

export type ThumbanilSize = 'full' | 'large' | 'medium' | 'small';

export enum InstanceViewMode {
  GroundTruth = 'groundTruth',
  Prediction = 'prediction',
}

export interface ServiceCredit {
  api: string;
  name: string;
  price: number;
  fixedAmount?: number;
}

export type BoxSuggestion = {
  id: number;
  ground_truth_box: RangeBox | null;
  ground_truth_class_id: number | null;
  suggested_box: RangeBox | null;
  suggested_class_id: number | null;
};

export type BoxSuggestionWithStatus = BoxSuggestion & {
  globalIndex: number;
  status?: 'accepted' | 'rejected';
};

export type LimitsInfo = {
  limit: number | null;
  currentCount: number;
  remainingLimit: number | null;
};

export enum AccountStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
}

export interface Account {
  id: AccountId;
  email: string;
  firstName?: string;
  lastName?: string;
  userName?: string; // some SSO company may not use email as primary key, a backup
  createdAt?: string;
  updatedAt?: string;
  activationCode?: string;
  status: AccountStatus;
  forgetPasswordCode?: string | null;
  forgetPasswordCodeCreatedAt?: string | null;
  zeroAuthAccountId?: string;
}

export enum LoginType {
  LandingPassword = 'landing_password',
  Google = 'google_sso',
  EnterpriseSSO = 'enterprise_sso',
  SnowflakeSSO = 'snowflake_sso',
  API = 'api', // used for test
}

export interface UserWithOrg extends User {
  organization: Organization;
}

export interface PendingUserWithOrg extends PendingUser {
  organization: Organization;
  token?: string;
}

export enum SignupType {
  Register = 'register',
  OrgInvitation = 'org_invitation',
}

export enum AuthCookieStatus {
  AccountLoggedIn = 'account',
  UserOrgLoggedIn = 'user',
}

// The types here mirror those here: https://github.com/landing-ai/avi-common/blob/15e776c9c591d6eac45ada0c304db5a5020b1a02/web/avi_web/entities/postprocess.py
export type InstantLearningPostprocessAlgorithm = {
  name: string;
  params: Record<string, any>;
};

export interface InstantLearningPostprocessParams {
  prediction_path: string;
  algorithms: InstantLearningPostprocessAlgorithm[];
}

export type InstantLearningPostprocessContour = {
  x: number[];
  y: number[];
};

export type InstantLearningPostprocessPoint = {
  x: number;
  y: number;
};

export type InstantLearningPostprocessBlobStats = {
  label_index: number;
  bounding_box: RangeBox;
  area: number;
  centroid: InstantLearningPostprocessPoint;
  contour?: InstantLearningPostprocessContour;
};

export type InstantLearningPostprocessBlobStatsResult = {
  blob_stats: InstantLearningPostprocessBlobStats[];
  area_mask?: string;
};

// The exact format of the result depends on the algorithm chosen.
// Right now since only the blob_stats algorithm is implemented in avi-web,
// this is only InstantLearningPostprocessBlobStatsResult. However,
// if more algorithms were implemented, this would then become a union type.
export type InstantLearningPostprocessResult = InstantLearningPostprocessBlobStatsResult;

export type ImageLevelClassificationRuleParams = any;

export type ImageLevelClassificationRule = {
  operator: string;
  defectIds: ImageLevelClassificationRuleParams;
  classification: string;
};

export type ImageLevelClassificationRuleCollection = {
  rules: ImageLevelClassificationRule[];
  defaultClassification: string;
};

export interface PostProcessing {
  noiseFilter: Record<number, number>;
  classificationRules: ImageLevelClassificationRuleCollection;
  defectMap: any;
}

export enum SubscriptionName {
  /** @deprecated */
  Trialing = 'trialing',
  /** @deprecated */
  Freemium = 'freemium',
  /** @deprecated */
  Starter = 'starter',
  Free = 'free',
  Visionary = 'visionary',
  PreSales = 'pre_sales',
  Enterprise = 'enterprise',
}

/**
 * @deprecated remove after new pricing is fully rolled out
 */
export enum TemporaryProcessedSubscriptionName {
  TrialingV1 = 'trialing_v1',
  FreemiumV1 = 'freemium_v1',
  StarterV1 = 'starter_v1',
  VisionaryV1 = 'visionary_v1',
  Free = 'free',
  Visionary = 'visionary',
  PreSales = 'pre_sales',
  Enterprise = 'enterprise',
}

export enum SubscriptionStatus {
  Active = 'active',
  Trialing = 'trialing',
  Canceled = 'canceled',
  ToBeCanceled = 'to_be_canceled',
}

export enum UsageAccumulation {
  Month = 'month',
  Ever = 'ever',
}

export type SubscriptionMeta = {
  // General
  quotaProject: number; // Deprecated
  quotaUsage: number;
  quotaUser?: number;
  quotaActiveProject?: number;
  apiLimitPaths: string[];
  usageRecurringInterval: 'month' | 'year';
  plan?: string;
  planTier?: string;
  // Data management
  allowLabelQualityCheck: boolean;
  // Admin portal only
  /** @deprecated new records will write to subscription.audit_logs */
  usageQuotaChanges?: {
    quotaUsageChange: number;
    userEmail: string;
    notes: string;
  };
  /** @deprecated new records will write to subscription.audit_logs */
  ruleChanges?: {
    quotasChanged: object;
    userEmail: string;
    notes: string;
  };
} & Record<string, string | number | boolean | string[]>;

export enum SubscriptionChangeType {
  QuotaUsage = 'quota_usage',
  QuotaUser = 'quota_user',
  QuotaActiveProject = 'quota_active_project',
  EndDate = 'end_date',
  CancelSubscription = 'cancel_subscription',
  CreateSubscription = 'create_subscription',
}

export type SubscriptionAuditLog = {
  type: SubscriptionChangeType;
  oldValue: number | string | object | null;
  newValue: number | string | object | null;
  timestamp: DateStringISO;
  userEmail: string;
  notes: string;
};

export interface Subscription {
  id: number;
  stripeSubscriptionId: string;
  orgId: OrgId;
  stripeCustomerId: string;
  /**
   * In pricing v2, this is the id of the base price.
   * Price ID of 0 charge for Free plan.
   * Price ID of $50/month for Visionary plan.
   */
  stripePriceId: string;
  stripeProductId: string;
  stripeProductName: 'enterprise' | 'freemium' | (string & {});
  /**
   * In pricing v2, this is the id of the base subscription item.
   * Subscription item ID of 0 charge for Free plan.
   * Subscription item ID of $50/month for Visionary plan.
   */
  stripeSubscriptionItemId: string;
  /**
   * In pricing v2, this is the id of the usage based subscription item.
   * We use this ID to
   *  1. report usage records to Stripe.
   *  2. find usage-price from upcoming invoice items.
   * Only Visionary plan has usage based subscription item.
   */
  stripeUsageBasedSubscriptionItemId?: string | null;
  stripeBillingCycleAnchor: DateStringISO;
  stripeCurrentPeriodStart: DateStringISO;
  stripeCurrentPeriodEnd: DateStringISO;
  stripeRecurringInterval: string;
  accumulationInterval?: UsageAccumulation.Ever | UsageAccumulation.Month | (string & {});
  meta: SubscriptionMeta;
  usageQuota: number;
  status?: 'trialing' | 'active' | 'to_be_canceled' | (string & {});
  createdAt?: DateStringISO;
  updatedAt?: DateStringISO;
  // The user who created or update this subscription
  updatedByUserId?: string;
  // stripe event id, for tracking
  eventId?: string;
  /**
   * Accumulated usage of this subscription in the current cycle
   */
  accuUsage: number;
  auditLogs: SubscriptionAuditLog[];
}

export interface UsageRecord {
  id: number;
  stripeSubscriptionId: string;
  orgId: OrgId;
  projectId?: ProjectId;
  projectName?: string;
  source?: string;
  meta?: Record<string, string>;
  idempotentKey: string;
  usage: number;
  timestamp: DateStringISO;
}

export interface Usage {
  stripeSubscriptionId: string;
  orgId: OrgId;
  projectId?: ProjectId;
  source?: string;
  meta?: Record<string, string>;
  idempotentKey: string;
  usage: number;
  timestamp: DateStringISO;
  periodStart: DateStringISO;
  periodEnd: DateStringISO;
}

export interface UsageSummary {
  stripeSubscriptionId: string;
  orgId: OrgId;
  usage: number;
  projectCount: number;
  maxProjectMediaCount: number | null;
  labeledImages?: number;
  trainedModels: number;
  uploadedImages: number;
  billingCycles: { cycleStart: DateStringISO; cycleEnd: DateStringISO }[];
}

export enum UpgradePurpose {
  MoreProjects = 'more_projects',
  MoreClasses = 'more_classes',
  MoreImages = 'more_images',
  MoreSavedModels = 'more_models',
}

export type StripeProductConstantItem = {
  id: string;
  name: string;
  /** @deprecated */
  portalConfigurationId: string;
  /** @deprecated */
  price: {
    month: string;
    year?: string;
  };
  basePrice?: string;
  usageBasedPrice?: string;
};

export type StripeProductConstants = {
  freePackage: StripeProductConstantItem;
  visionaryPackage: StripeProductConstantItem;
  /** @deprecated */
  [productName: string]: StripeProductConstantItem;
};

export interface SubscriptionPlans {
  name: string;
  metadata?: SubscriptionMeta;
  tiers?: {
    tier: number; // 1, 2, 3, 4, 5
    stripeProductId: string;
    credits: number; // quotaUsage
    prices?: {
      month: {
        price: number;
        stripePriceId: string;
      };
      year?: {
        price: number; // (year price) / 12
        stripePriceId: string;
      };
    };
  }[];
}

export interface ExampleProject {
  id: number;
  orgId: number;
  projectId: number;
  datasetId: number;
  title: string;
  description: string;
  trialMediaIds: MediaId[];
  enable: boolean;
  coverMediaId: number | null;
  defectMap: Defect[];
}

export interface SubscriptionTestClock {
  orgId: number;
  currentTime: DateStringISO;
}

// Vela publish API requires to use snake_case
export interface VelaEventContent {
  payload: Object;
  action: string;
}

export interface VelaEvent {
  content: VelaEventContent;
  type: string;
  timestamp: number;
  user_id: string;
}

export type MediaCountInUsagePage = {
  orgId: number;
  projectId: number;
  projectName?: string;
  mediaCount: number;
  startTime?: DateStringISO;
  endTime?: DateStringISO;
  uploadTime?: DateStringISO;
};

export type ModelCountInUsagePage = {
  orgId: number;
  projectId: number;
  modelCount: number;
  startTime?: DateStringISO;
  endTime?: DateStringISO;
  createdAt?: DateStringISO;
};

export type OrgSetting = {
  predictUponUploading?: boolean;
  /**
   * When migrate from legacy plans to pricing v2 plans, the migration script will add this value.
   */
  pricingV2MigratedAt?: DateStringISO;
  /**
   * When user confirmed, backend will add this value.
   */
  pricingV2ConfirmedAt?: DateStringISO;
  /**
   * Wether to show landing edge and docker options in deploy page
   */
  hideLeAndDocker?: boolean;
};

export enum InstantPredictionSource {
  uploadPrediction = 'upload_prediction',
  getPredictionAPI = 'get_prediction_api',
}

export interface PostProcLabel {
  id: number;
  orgId: number;
  mediaId: number;
  modelId: string;
  classString: string;
  filteredMaskPath: string;
}

export interface TempUserHistory {
  accountId: string;
  userId: string;
  /**
   * For admin portal v2, an admin account is not required to exist in CLEF db, because
   * admin portal v2 uses Google SSO to login. Instead, adminEmail is used to record admin email.
   */
  adminAccountId?: string | null;
  orgId: number;
  readOnly: boolean;
  createdAt: Date;
  expirationDate: Date;
  adminEmail?: string | null;
}

export type ZeroAuthRunRecord = {
  id: number;
  zeroAuthAccountId: string;
  zeroAuthProjectId: number;
  mediaPath: string;
  gtSegPath: string;
  predSegPath: string;
  createdAt: string;
  updatedAt?: string;
  status: string;
  width?: number;
  height?: number;
};

export type ZeroAuthProject = {
  id?: number;
  name: string;
  dataset: Partial<ZeroAuthRunRecord>[];
  defectMap: DefectMapWithDetails;
  brushSize: number;
  labelingGuideImages?: [string, string];
};

export type ZeroAuthTrainAnnotation = {
  defectId: number;
  rangeBox: RangeBox;
  segmentationBitmapEncoded: string;
};

export type ZeroAuthLabel = {
  annotations: ZeroAuthTrainAnnotation[];
  isChanged?: boolean;
};

export type ZeroAuthTrainParams = {
  zeroAuthProjectId: number;
  zeroAuthLabels: ZeroAuthLabel[];
};

export enum ZeroAuthRunRecordStatus {
  TRAINING = 'training',
  COMPLETED = 'completed',
  RESET = 'reset',
}

export type TrainHealth = {
  occupation: number;
  active: boolean;
};

export type TrainHealthData = {
  instantLearning: TrainHealth;
  fastAndEasy: TrainHealth;
  queueSize: number;
  serviceStatus: 'READY' | 'PENDING' | 'SUSPENDED';
};

export type UTMParams = {
  utm_campaign?: string | null;
  utm_source?: string | null;
  utm_content?: string | null;
  utm_term?: string | null;
  utm_medium?: string | null;
};

export type PublicEndpoint = {
  id: number;
  orgId: number;
  endpointId: string;
  createdByUserId: string;
  enable: boolean;
  projectId?: number;
  expireAt?: DateStringISO;
  type: 'inference' | 'image_upload' | (string & {});
};

export enum ApiKeyStatus {
  ACTIVE = 'ACTIVE',
  REVOKED = 'REVOKED',
}

export type ApiKey = {
  id: number;
  userId: UserId;
  key: string;
  version: 'v1' | 'v2';
  expirationDate?: Date;
  lastUsedAt?: Date;
  createdAt?: Date;
  status?: ApiKeyStatus | string;
  name?: string;
};

export type MediaConfusionMatrix = {
  id: number;
  orgId: number;
  projectId: number;
  modelId: string;
  datasetVersionId: number;
  mediaId: number;
  gtClassId: number;
  predClassId: number;
  threshold: number;
  count: number;
  createdAt: DateStringISO;
};

export type AggregatedConfusionMatrix = {
  gtClassId: number;
  predClassId: number;
  count: number;
};

export enum SplitConfusionMatricesKey {
  CORRECT = 'correct',
  MC = 'misClassification',
  FP = 'falsePositive',
  FN = 'falseNegative',
}

export type SplitConfusionMatrices = {
  [key in SplitConfusionMatricesKey]: ConfusionMatrixSummary;
};

export type ModelEvaluationReportPerformance = {
  f1: number | undefined;
  precision: number | undefined;
  recall: number | undefined;
};

export type EvaluationSet = {
  id: number;
  orgId: number;
  projectId: number;
  datasetVersionId: number;
  createdAt: DateStringISO;
  splitId?: number | null;
  hidden: boolean;
};

export type ConfusionMatrixSummary = {
  count: number;
  data: AggregatedConfusionMatrix[];
};

export type ModelEvaluationReport = {
  id: number;
  orgId: number;
  projectId: number;
  evaluationSetId: number;
  modelId: string;
  threshold: number;
  evaluationId?: string;
  status: ModelEvaluationReportStatus;
  createdAt: DateStringISO;
  updatedAt?: DateStringISO;
  triggerType?: string;
  confusionMatrices?: SplitConfusionMatrices | null;
  performance?: ModelEvaluationReportPerformance | null;
};

export enum ModelEvaluationReportTriggerType {
  USER = 'user',
  AUTO = 'auto',
}

export enum ModelEvaluationReportStatus {
  STARTED = 'started',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export type ModelComparisonReport = {
  id: number;
  orgId: number;
  projectId: number;
  baseModelId: string;
  baseThreshold: number;
  candidateModelId: string;
  candidateThreshold: number;
  evaluationSetId: number;
  name: string;
  description?: string;
  favorite: boolean;
  createdAt: DateStringISO;
  updatedAt?: DateStringISO;
};

export type ModelComparisonReportResponseItem = ModelComparisonReport & {
  baseModel: RegisteredModel;
  candidateModel: RegisteredModel;
  evaluationSet: EvaluationSet & {
    datasetVersion: Pick<DatasetVersion, 'id' | 'datasetId' | 'version' | 'name'> & {
      dataset: { name: string };
      split: { id: number; splitSetName: string };
    };
  };
};

export enum QUEUE_NAMES {
  GENERATE_XML_SEGMASK = 'generate_xml_segmask',
  STORE_CONFUSION_MATRIX = 'store_confusion_matrix',
  CALCULATE_USAGE_ACCU = 'calculate_usage_accu',
  EXPORT_DATASET = 'export_dataset',
}

export type GenerateXmLSegmaskTaskRequest = {
  orgId: number;
  projectId: number;
  datasetId: number;
  labelerId: string;
  labelType: LabelType;
  bucket: string;
  defectIdToName: Record<number, string>;
  defectIdToIndex: Record<number, number>;
  mediaBatch: {
    mediaId: number;
    labelId: number;
    mediaName: string;
    width: number;
    height: number;
    annotations: AnnotationBase[] | Annotation[] | undefined;
  }[];
};

export type ExportDatasetTaskRequest = {
  orgId: number;
  version: number;
  defectMap: DefectMapWithDetails;
  csvPath: string;
  bucket: string;
  datasetName: string;
  datasetId: number;
  projectId: number;
  onlyClassification: boolean;
};

export type StoreConfusionMatrixTaskRequest = {
  jobId: string; // uuid generated by triggerer
  orgId: number;
  modelEvaluationReportId: number;
  mediaIds: number[];
  checkMetricsReady?: boolean; // update metrics ready for training flow
};

export type StoreConfusionMatrixTaskResult = {
  isAllJobsSettled: boolean | undefined;
  modelEvaluationReport: ModelEvaluationReport | undefined;
};

export type Tag = {
  id: number;
  orgId: number;
  projectId: number;
  name: string;
  color: string | null;
  isArchived: boolean;
};

export type TagSnapshot = {
  [key: string]: {
    name: string;
    color: string | null;
  };
};

export enum ConfusionMatrixIndex {
  Background = 0,
}

export type ConfusionMatrixForAllThresholds = {
  [threshold: string]: {
    [groundTruthClassId: number]: {
      [predictionClassId: number]: number;
    };
  };
};

export type JobRedisEntry = { jobId: string; status: 'started' | 'failed' };

export type MetricsItem = {
  performance: number; // 0~1
  recall: number; // 0~1
  precision: number; // 0~1
};
export type ModelMetrics = {
  all: MetricsItem;
  background: MetricsItem;
  classes: { [classId: number]: MetricsItem };
};

export interface ModelMetricParam {
  modelId: string;
  evaluationSetId: number;
  threshold: number;
}

export type BatchModelMetricParam = Array<ModelMetricParam>;

export type SnapshotModelInfo = {
  id: ModelId;
  orgId: OrgId;
  projectId: ProjectId;
  confidence: number;
  datasetVersionId: number;
  status: ModelStatus;
  modelName: string;
  metricsReady: boolean;
  predictionCount: number;
  createdAt: DateStringISO | null;
  updatedAt: DateStringISO | null;
  updatedByUserId?: string;
};

export enum UpgradePlanNextAction {
  Pay = 'pay',
  Success = 'success',
}

export enum TermsAndConditionsType {
  PricingV2 = 'pricing_v2',
}

export type ModelDownloadXEventProperty = {
  model_id: string;
  source: string;
  project_id?: ProjectId;
  endpoint_name?: string;
};

export enum ActiveProjectActionTypeEnum {
  Add = 'add',
  Delete = 'delete',
}
export type ActiveProjectXEventProperty = {
  project_id: ProjectId;
  action_type: ActiveProjectActionTypeEnum;
};

export type XEvent<T> = {
  event: string;
  action: string;
  properties: T;
};

export type GetTrainingCreditCostRequest = {
  numEpochs: number;
  numLabeled: number;
  numUnlabeled: number;
  height: number;
  width: number;
  experimentType: ExperimentType;
};
