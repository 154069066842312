import React from 'react';
import { Drawer, makeStyles, List } from '@material-ui/core';
import classNames from 'classnames';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { ApiResponseLoader } from '@clef/client-library';
import { NEW_DRAWER_WIDTH, PageName } from '@clef/shared/constants';
import { createFRESidebarStructure } from './structure';
import { SidebarItem } from './FRESidebarSections';
import CLEF_PATH from '../../../../constants/path';
import SettingsIcon from '@material-ui/icons/Settings';
import { getTaskCount } from '../../../../utils/task_utils';
import { PROJECT_TYPE_ICON_LOOKUP } from '../../../ProjectCard/NewProjectCard';
import { useSidebarInfo } from './utils';
import { useModelAnalysisEnabled } from '../../../../hooks/useFeatureGate';

const useStyles = makeStyles(theme => ({
  listContainer: {
    backgroundColor: 'transparent',
    borderRight: 'none',
    width: NEW_DRAWER_WIDTH,
    boxShadow: 'none !important',
  },
  drawer: {
    width: NEW_DRAWER_WIDTH,
    zIndex: theme.zIndex.drawer,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    // For defect book printing
    '@media print': {
      display: 'none',
    },
  },
  toolbar: {
    minHeight: 48,
  },
  mainStructure: {
    flexGrow: 1,
    flexBasis: 0,
    padding: theme.spacing(2),
  },
  projectSettingsContainer: {
    padding: theme.spacing(2),
  },
  projectSettingsSidebarItem: {
    marginBottom: 0,
  },
  projectIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 60,
    '& img': {
      verticalAlign: 'middle',
      width: 20,
      height: 20,
    },
  },
}));

export interface SidebarProps {}

const Sidebar: React.FC<SidebarProps> = () => {
  const classes = useStyles();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const enableModelAnalysis = useModelAnalysisEnabled();
  const [layoutInfo, layoutInfoLoading, layoutInfoError] = useSidebarInfo();

  if (!selectedProject) {
    return null;
  }

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classNames(classes.listContainer, 'cy-sidebar'),
      }}
    >
      <div className={classes.toolbar} />
      <div className={classes.projectIcon}>
        {selectedProject.labelType && PROJECT_TYPE_ICON_LOOKUP[selectedProject.labelType]}
      </div>
      <ApiResponseLoader response={layoutInfo} loading={layoutInfoLoading} error={layoutInfoError}>
        {layoutInfoLoaded => {
          const { allowedSections: sections, tasksCount, modelsInProgressCount } = layoutInfoLoaded;
          const tasksCountPerPurpose = tasksCount[selectedProject.id] ?? {};
          let filteredAllowedSections = sections;
          if (!enableModelAnalysis) {
            filteredAllowedSections = filteredAllowedSections.filter(
              page => page !== PageName.ModelV2ListPage,
            );
          }
          const sidebarSections = createFRESidebarStructure(filteredAllowedSections, {
            [PageName.MyTasks]: getTaskCount(tasksCountPerPurpose),
            [PageName.ModelV2ListPage]: modelsInProgressCount,
          });
          return (
            <>
              <div className={classes.mainStructure}>
                <List component="nav" disablePadding>
                  {sidebarSections.map(section => (
                    <SidebarItem {...section} key={section.key} />
                  ))}
                </List>
              </div>
              {sections.includes(PageName.ProjectSettings) && (
                <div
                  className={classes.projectSettingsContainer}
                  data-testid="sidebar-project-settings"
                >
                  <SidebarItem
                    link={CLEF_PATH.projectSettings}
                    label={t('Settings')}
                    icon={<SettingsIcon />}
                    className={classes.projectSettingsSidebarItem}
                  />
                </div>
              )}
            </>
          );
        }}
      </ApiResponseLoader>
    </Drawer>
  );
};
export default Sidebar;
